import { Deserializable } from '@models/deserializable.model';

export class Attachment implements Deserializable {
  jobId: string;
  filename: string;
  file: string;
  fetchStatus: string;
  folder: string;

  constructor(input?: any) {
    this.jobId = null;
    this.filename = null;
    this.file = null;
    this.fetchStatus = null;
    this.folder = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
