export enum BankIDHintCode {
  OUTSTANDING_TRANSACTION = 'outstandingTransaction',
  NO_CLIENT = 'noClient',
  STARTED = 'started',
  USER_MRTD = 'userMrtd',
  USER_CALL_CONFIRM = 'userCallConfirm',
  USER_SIGN = 'userSign',
  EXPIRED_TRANSACTION = 'expiredTransaction',
  CERTIFICATE_ERR = 'certificateErr',
  USER_CANCEL = 'userCancel',
  CANCELLED = 'cancelled',
  START_FAILED = 'startFailed',
  USER_DECLINED_CALL = 'userDeclinedCall',
  NOT_SUPPORTED_BY_USER_APP = 'notSupportedByUserApp',
}
