export enum ServiceType {
  BLANK = 'BLANK',
  COWORKER = 'COWORKER',
  SPECIALIST = 'SPECIALIST',
  EXECUTIVE = 'EXECUTIVE',
  COMPANY = 'COMPANY',
  BOARDMEMBER = 'BOARDMEMBER',
  SCREENING = 'SCREENING',
  PRESCREEN = 'PRESCREEN',
  LFD_FOLLOWUP = 'LFD_FOLLOWUP',
  SECURITY_SCREENING_BASIC = 'SECURITY_SCREENING_BASIC',
  SECURITY_SCREENING = 'SECURITY_SCREENING',
  SECURITY_CLEARANCE = 'SECURITY_CLEARANCE',
  SECURITY_SCREENING_IMPORT = 'SECURITY_SCREENING_IMPORT'
}
