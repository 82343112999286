import { Injectable } from '@angular/core';
import { CustomerAccess } from '@enums/customer-access.enum';
import { UserGroupId } from '@enums/user-group-id.enum';
import { Customer } from '@models/customer.model';
import { User } from '@models/user.model';

export class UserPermission {
  id: string;
  name: UserGroupId;
  requirement: CustomerAccess;
  children: UserPermission[];
  active: boolean;
  toggle: boolean;

  constructor(id: string, name: UserGroupId, requirement?: CustomerAccess, children?: UserPermission[]) {
    this.id = id;
    this.name = name;
    this.requirement = requirement ? requirement : null;
    this.children = children ? children : [];
    this.active = false;
    this.toggle = false;
  }
}

@Injectable()
export class UserPermissionHelperService {
  private permissions: UserPermission[] = [
    new UserPermission("1", UserGroupId.USER_ADMIN),
    new UserPermission("2", UserGroupId.COORDINATOR),
    new UserPermission("3", UserGroupId.ANALYST),
    new UserPermission("4", UserGroupId.SPI_COORDINATOR),
    new UserPermission("5", UserGroupId.SPI_LEADER),
    new UserPermission("6", UserGroupId.DATA_COLLECTOR),
    new UserPermission("7", UserGroupId.CUSTOMER_ADMIN),
    new UserPermission("8", UserGroupId.CUSTOMER_SUPERUSER),
    new UserPermission("9", UserGroupId.SERVICE_BACKGROUND_CHECK, CustomerAccess.BackgroundCheck, [
      new UserPermission("10", UserGroupId.BACKGROUND_CHECK_ORDERER, null, [
        new UserPermission("11", UserGroupId.BACKGROUND_CHECK_ORDERER_SCREEN, null, [
          new UserPermission("12", UserGroupId.SCREENING_EXTRA_SERVICES)
        ]),
        new UserPermission("13", UserGroupId.BACKGROUND_CHECK_ORDERER_COWORKER),
        new UserPermission("14", UserGroupId.BACKGROUND_CHECK_ORDERER_SPECIALIST),
        new UserPermission("15", UserGroupId.BACKGROUND_CHECK_ORDERER_EXECUTIVE)
      ]),
      new UserPermission("16", UserGroupId.BACKGROUND_CHECK_RECIPIENT),
      new UserPermission("17", UserGroupId.BACKGROUND_CHECK_DOWNLOAD_REPORT)
    ]),
    new UserPermission("18", UserGroupId.SERVICE_REFERENCE_CHECK, CustomerAccess.ReferenceCheck, [
      new UserPermission("19", UserGroupId.REFERENCE_CHECK_ORDERER),
      new UserPermission("20", UserGroupId.REFERENCE_CHECK_RECIPIENT),
      new UserPermission("21", UserGroupId.REFERENCE_CHECK_QUESTIONNAIRE_CREATOR),
      new UserPermission("22", UserGroupId.REFERENCE_CHECK_DOWNLOAD_REPORT),
      new UserPermission("23", UserGroupId.REFERENCE_CHECK_DELETE)
    ]),
    new UserPermission("24", UserGroupId.SERVICE_LFD, CustomerAccess.LFD, [
      new UserPermission("25", UserGroupId.LFD_ORDERER),
      new UserPermission("26", UserGroupId.LFD_RECIPIENT),
      new UserPermission("27", UserGroupId.LFD_ARCHIVED),
      new UserPermission("28", UserGroupId.LFD_DOWNLOAD_REPORT),
      new UserPermission("29", UserGroupId.LFD_DELETE),
      new UserPermission("30", UserGroupId.LFD_SCHEDULED)
    ]),
    new UserPermission("31", UserGroupId.SERVICE_PROTECTIVE_SECURITY, CustomerAccess.ProtectiveSecurity, [
      new UserPermission("32", UserGroupId.PROTECTIVE_SECURITY_SECURITY_MANAGER, null, [
        new UserPermission("33", UserGroupId.SECURITY_CLEARANCE_SCHEDULED, CustomerAccess.SecurityClearance, [
          new UserPermission("34", UserGroupId.SECURITY_CLEARANCE_APPROVE_ACTIVATION)
        ]),
        new UserPermission("35", UserGroupId.PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED, null, [
          new UserPermission("36", UserGroupId.PROTECTIVE_SECURITY_IMPORT_ORDER),
          new UserPermission("37", UserGroupId.PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS)
        ])
      ]),
      new UserPermission("38", UserGroupId.PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER, null, [
        new UserPermission("39", UserGroupId.SECURITY_CLEARANCE_SCHEDULED, CustomerAccess.SecurityClearance, [
          new UserPermission("40", UserGroupId.SECURITY_CLEARANCE_APPROVE_ACTIVATION)
        ]),
        new UserPermission("41", UserGroupId.PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED, null, [
          new UserPermission("42", UserGroupId.PROTECTIVE_SECURITY_IMPORT_ORDER),
          new UserPermission("43", UserGroupId.PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS)
        ])
      ]),
      new UserPermission("44", UserGroupId.PROTECTIVE_SECURITY_SECURITY_STAFF, null, [
        new UserPermission("45", UserGroupId.SECURITY_CLEARANCE_SCHEDULED, CustomerAccess.SecurityClearance, [
          new UserPermission("46", UserGroupId.SECURITY_CLEARANCE_APPROVE_ACTIVATION)
        ]),
        new UserPermission("47", UserGroupId.PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED, null, [
          new UserPermission("48", UserGroupId.PROTECTIVE_SECURITY_IMPORT_ORDER),
          new UserPermission("49", UserGroupId.PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS)
        ])
      ]),
      new UserPermission("50", UserGroupId.SECURITY_CLEARANCE_RESPONSIBLE_MANAGER, CustomerAccess.SecurityClearance),
      new UserPermission("51", UserGroupId.PROTECTIVE_SECURITY_ORDERER, null, [
        new UserPermission("52", UserGroupId.SECURITY_SCREENING_ORDERER_BASIC, CustomerAccess.SecurityScreening),
        new UserPermission("53", UserGroupId.SECURITY_SCREENING_ORDERER, CustomerAccess.SecurityScreening),
        new UserPermission("54", UserGroupId.SECURITY_CLEARANCE_ORDERER, CustomerAccess.SecurityClearance),
      ]),
      new UserPermission("55", UserGroupId.PROTECTIVE_SECURITY_RECIPIENT, null, [
        new UserPermission("56", UserGroupId.SECURITY_SCREENING_RECIPIENT, CustomerAccess.SecurityScreening),
        new UserPermission("57", UserGroupId.SECURITY_CLEARANCE_RECIPIENT, CustomerAccess.SecurityClearance),
      ]),
      new UserPermission("58", UserGroupId.PROTECTIVE_SECURITY_DOWNLOAD_REPORT, null, [
        new UserPermission("59", UserGroupId.SECURITY_SCREENING_DOWNLOAD_REPORT, CustomerAccess.SecurityScreening),
        new UserPermission("60", UserGroupId.SECURITY_CLEARANCE_DOWNLOAD_REPORT, CustomerAccess.SecurityClearance),
        new UserPermission("61", UserGroupId.SPI_DOWNLOAD_REPORT, CustomerAccess.SecurityScreening)
      ]),
      new UserPermission("62", UserGroupId.PROTECTIVE_SECURITY_CHANGE_RECIPIENT),
      new UserPermission("63", UserGroupId.PROTECTIVE_SECURITY_TAG, null, [
        new UserPermission("64", UserGroupId.PROTECTIVE_SECURITY_EDIT_TAG),
        new UserPermission("65", UserGroupId.PROTECTIVE_SECURITY_DELETE_TAG)
      ]),

    ])
  ];
  sriOnlyPermissions: UserGroupId[] = [
    UserGroupId.USER_ADMIN,
    UserGroupId.COORDINATOR,
    UserGroupId.ANALYST,
    UserGroupId.SPI_COORDINATOR,
    UserGroupId.SPI_LEADER,
    UserGroupId.DATA_COLLECTOR
  ];

  constructor() { }

  getPermissions(): UserPermission[] {
    const permissions: UserPermission[] = this.deepCopyPermissions(this.permissions);

    return permissions;
  }

  filterFailedRequirements(permissions: UserPermission[], customer: Customer): UserPermission[] {
    const removeFromPermissions = [];

    permissions.forEach(permission => {
      if (permission.requirement !== null) {
        let accessIndex = customer.access.map((access) => access).indexOf(permission.requirement);
        if (accessIndex === -1) {
          removeFromPermissions.push(permission);
        }
      }
      if (permission.children !== null && permission.children.length > 0) {
        this.filterFailedRequirements(permission.children, customer);
      }
    });

    removeFromPermissions.forEach(permission => {
      let index = permissions.map((groupId) => groupId.name).indexOf(permission.name);
      permissions.splice(index, 1);
    });

    return permissions;
  }

  filterSriOnlyPermissions(permissions: UserPermission[]): UserPermission[] {

    this.sriOnlyPermissions.forEach(group => {
      let index = permissions.map(groupId => groupId.name).indexOf(group);
      permissions.splice(index, 1);
    });

    return permissions;
  }

  toggleActivePermissions(permissions: UserPermission[], user: User): UserPermission[] {
    for (const permission of permissions) {
      permission.active = user.isInUserGroup(permission.name);
      this.toggleActivePermissions(permission.children, user);
    }

    return permissions;
  }

  private deepCopyPermissions(permissions: UserPermission[]): UserPermission[] {
    return JSON.parse(JSON.stringify(permissions)); // TODO: Refine deep copy
  }
}
