import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentType } from '@enums/document-type.enum';
import { CreateDocumentBody, DocumentService } from '@services/document.service';
import { DocumentGroup } from '@enums/document-group.enum';
import { DocumentRefType } from '@enums/document-ref-type.enum';
import { Job } from '@models/job.model';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  @Input() selectedFile: File | null = null;
  @Input() job: Job;
  @Output() selectedFileChange = new EventEmitter<File>();
  @Output() uploadSuccess = new EventEmitter<void>();
  isUploading: boolean = false;
  error: string;

  constructor(
    private docHttp: DocumentService
  ) { }

  ngOnInit() { }

  onUploadDocument() {
    if (!this.selectedFile) {
      return;
    }
    this.isUploading = true;
    this.error = null;

    const body = this.createDocumentBody();
   
    this.docHttp.postDocument(body).subscribe(response => {
      this.uploadFileToS3(response);
    }, error => {
      this.error = error.message;
      this.isUploading = false;
    });
  }

  createDocumentBody() {
    const fileFormat = this.getFileExtension(this.selectedFile)
    if (fileFormat === 'unknown') {
      this.error = 'Filen måste vara i PDF-format.';
      this.isUploading = false;
      return;
    }
    const body: CreateDocumentBody = {
      refId: this.job.baseInfoId,
      refType: DocumentRefType.PERSON,
      group: DocumentGroup.PROTECTIVE_SECURITY,
      type: this.getDocumentType(this.job),
      format: fileFormat
    };
    return body;
  }

  uploadFileToS3(response) {
    this.docHttp.uploadFileS3(response.uploadUrl, this.selectedFile).subscribe(
      () => {
        this.isUploading = false;
        this.selectedFile = null;
        this.uploadSuccess.emit();
      },
      error => {
        this.error = error.message;
        this.isUploading = false;
      }
    )
  }

  getDocumentType(job: Job): DocumentType {
    if (job.isServiceSecurityClearance()) {
      return DocumentType.PS_SECURITY_INTERVIEW;
    } else if (job.isGU()) {
      return DocumentType.PS_SPI;
    }
  }

  getFileExtension(file: File): string {
    const mimeType = file.type;
    const mimeToExtension: { [key: string]: string } = {
      'application/pdf': 'pdf',
    };
    return mimeToExtension[mimeType] || 'unknown';
  }

  onFileInputChange(event: any) {
    const file = event.target.files[0];
    if (!file || file.type !== 'application/pdf') {
      return;
    }
    this.selectedFile = file;
    this.selectedFileChange.emit(file);
  }
}
