import { ScreeningCheckpoint } from "@enums/screening-checkpoint.enum";

export class CustomerScreeningCheckpoint {
    checkpoint: ScreeningCheckpoint;
    cost: number;
    checked?: boolean;
    standardChecked?: boolean;
    dataCollectorComment?: string;
    checkpointCollected: boolean;
    checkpointStarted: boolean;

    constructor(input?: any) {
        this.checkpoint = null;
        this.cost = 0;
        this.checked = null;
        this.standardChecked = null;
        this.dataCollectorComment = null;
        this.checkpointCollected = null;
        this.checkpointStarted = null;

        if (input) { this.deserialize(input); }
    }

    deserialize?(input: any): this {
        Object.assign(this, input);
        return this;
    }
}