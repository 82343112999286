import { Deserializable } from '@models/deserializable.model';

export class CreditSafeHistoricalDebtDetailsData implements Deserializable {
  DATE: string;
  NUMBER_PUBLIC: string;
  NUMBER_PRIVATE: string;
  TOTAL_BALANCE: string;

  constructor(input?: any) {
    this.DATE = '';
    this.NUMBER_PUBLIC = '';
    this.NUMBER_PRIVATE = '';
    this.TOTAL_BALANCE = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
