import { Deserializable } from '@models/deserializable.model';
import { Gender } from 'src/app/shared/enums/gender.enum';

export class RefCandidateInfo implements Deserializable {
  name: string;
  ssn: string;
  email: string;
  phone: string;
  age: number;
  gender: Gender;
  ip: string;

  constructor(input?: any) {
    this.name = '';
    this.ssn = '';
    this.email = '';
    this.phone = '';
    this.age = 0;
    this.gender = Gender.MALE;
    this.ip = '';
  
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  validate(): string {
    if (!this.name) { return 'Namn saknas'; }
    if (!this.ssn) { return 'Personnummer ogiltigt'; }
    if (!this.email) { return 'Email saknas'; }
    if (!this.phone) { return 'Telefonnummer ogiltigt'; }

    return null;
  }
}
