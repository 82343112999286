import { Deserializable } from './deserializable.model';

export class CustomerScreeningQuestion implements Deserializable {
  question: string;

  constructor(input?: any) {
    this.question = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
