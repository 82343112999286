import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-message',
  templateUrl: './progress-message.component.html',
  styleUrls: ['./progress-message.component.css']
})
export class ProgressMessageComponent implements OnInit {
  @Input() info: string;
  @Input() success: boolean;
  interval = 500;
  hourglass = 'hourglass_empty';

  constructor() { }

  ngOnInit() {
	this.tick();
  }

  tick() {
	if(this.hourglass === 'hourglass_empty')
		this.hourglass = 'hourglass_full';
	else
		this.hourglass = 'hourglass_empty';

	setTimeout(() => {
		this.tick();
		}, this.interval);
  }
}
