import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Service } from '@enums/service';
import { environment } from '@environments/environment';
import { APIKeyData } from '@models/api-key-data.model';
import { Attachment } from '@models/attachment.model';
import { Audit, SetAuditResultReadClass } from '@models/audit.model';
import { BkgLambdaResponse } from '@models/bkg-lambda-response.model';
import { CollectedData } from '@models/collected-data.model';
import { Consent } from '@models/consent.model';
import { CreditSafePerson } from '@models/creditsafe-person.model';
import { Customer } from '@models/customer.model';
import { DataPart } from '@models/data-part.model';
import { Job } from '@models/job.model';
import { Order } from '@models/order.model';
import { Report } from '@models/report.model';
import { Schedule } from '@models/schedule.model';
import { User } from '@models/user.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserGroupId } from '../../shared/enums/user-group-id.enum';
import { JobRiscGrades } from '../../shared/models/job-riscgrades.model';
import { Person } from '@models/person.model';
import { UploadReportBody } from '@models/upload-report-body.model';
import { OrderForPersonBody } from '@models/order-for-person-body.model';
import { PsPerson } from '../../shared/models/ps-person.model';
import { SecurityClass } from '@models/security-class.model';

@Injectable()
export class HttpBackgroundCheckService {
  private headers = new HttpHeaders();
  private baseUrl = environment.apiUrl;
  
  constructor(
    private http: HttpClient
  ) {
    this.setHeaders();
  }

  private setHeaders() {
    this.headers = this.headers.append('x-api-key', environment.apiKey);
  }

  postUser(body: User): Observable<User> {
    const endpoint = '/user';
    return this.http.post<User>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new User(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kontot kunde inte skapas'));
      })
    );
  }

  deleteUser(body: User): Observable<User> {
    const endpoint = '/user/delete';
    return this.http.post<User>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new User(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kontot kunde inte raderas'));
      })
    );
  }

  enableUserResetPassword(body: User): Observable<User> {
    const endpoint = '/user/reset';
    return this.http.post<User>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new User(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Lösenord kunde inte återställas'));
      })
    );
  }

  getUser(body: User): Observable<User> {
    const endpoint = '/user/populate';
    return this.http.post<User>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new User(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Användaren kunde inte hämtas'));
      })
    );
  }

  getUsers(role: UserGroupId): Observable<User[]> {
    const endpoint = '/users';
    const params = { 'role': role };
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new User(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Användare kunde inte hämtas'));
      })
    );
  }

  getUsersAdmin(role: UserGroupId, customerId: string): Observable<User[]> {
    const endpoint = '/admins/users';
    const params = new HttpParams()
      .append('role', role.toString())
      .append('customerId', customerId.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new User(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Användare kunde inte hämtas'));
      })
    );
  }

  postUsers(body: Customer): Observable<User[]> {
    const endpoint = '/users';
    return this.http.post<User[]>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        if (response && response.length) {
          const code = response[0].fetchStatus.split(':')[0];
          if (code === 'OK') {
            return response.map(user => new User(user));
          } else {
            throw (new Error(code));
          }
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Konton kunde inte hämtas'));
      })
    );
  }

  getCustomer(body: Customer): Observable<Customer> {
    const endpoint = '/customer/populate';
    return this.http.post<Customer>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Customer(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunden kunde inte hämtas'));
      })
    );
  }

  postCustomer(body: Customer): Observable<Customer> {
    const endpoint = '/customer';
    return this.http.post<Customer>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Customer(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunden kunde inte skapas'));
      })
    );
  }

  deleteCustomer(body: Customer): Observable<Customer> {
    const endpoint = '/customer/delete';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Customer(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunden kunde inte raderas'));
      })
    );
  }

  getCustomers(): Observable<Customer[]> {
    const endpoint = '/customers';
    return this.http.get<Customer[]>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        if (response && response.length) {
          const code = response[0].fetchStatus.split(':')[0];
          if (code === 'OK') {
            return response.map(customer => new Customer(customer));
          } else {
            throw (new Error(code));
          }
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunder kunde inte hämtas'));
      })
    );
  }

  createAPIKey(body: APIKeyData): Observable<APIKeyData> {
    const endpoint = '/customer/createapikey';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new APIKeyData(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte skapa/hämta API-nyckel'));
      })
    );
  }

  postOrder(body: Order): Observable<Order> {
    const endpoint = '/order';
    return this.http.post<Order>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Order(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Beställning kunde inte sparas'));
      })
    );
  }

  CreateOrderForPerson(personId: string, body: OrderForPersonBody): Observable<Order> {
    const endpoint = `/persons/${personId}/orders`;
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Order(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte skapa upp Grundutredning importerad'));
      })
    );
  }

  postOrderDelete(body: Order): Observable<Order> {
    const endpoint = '/order/abort';
    return this.http.post<Order>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Order(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Beställning kunde inte raderas'));
      })
    );
  }

  getOrder(body: Order): Observable<Order> {
    const endpoint = '/order/populate';
    return this.http.post<Order>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Order(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Beställning kunde inte hämtas'));
      })
    );
  }

  postOrderInit(body: Order): Observable<Order> {
    const endpoint = '/order/init';
    return this.http.post<Order>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Order(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Beställning kunde inte initieras'));
      })
    );
  }

  getOrders(page: number, size: number, service: Service): Observable<Order[]> {
    const endpoint = '/orders';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString())
      .append('service', service.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Order(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Beställningar kunde inte hämtas'));
      })
    );
  }

  getLfdList(page: number, size: number, jobState?: string): Observable<Job[]> {
    const endpoint = '/order/lfd';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('LFD-beställningar kunde inte hämtas'));
      })
    );
  }

  getLfdListArchived(page: number, size: number, jobState?: string): Observable<Order[]> {
    const endpoint = '/order/lfdarchived';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Order(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Arkiverade LFD-beställningar kunde inte hämtas'));
      })
    );
  }

  getLfdListScheduled(page: number, size: number, jobState?: string): Observable<Order[]> {
    const endpoint = '/order/lfdscheduled';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Order(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemalagda LFD-beställningar kunde inte hämtas'));
      })
    );
  }

  getJob(body: Job): Observable<Job> {
    const endpoint = '/job/populate';
    return this.http.post<Job>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Job(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärendet kunde inte hämtas'));
      })
    );
  }

  approveJob(body: Job): Observable<Job> {
    const endpoint = '/job/approve';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärendet kunde inte hämtas'));
      })
    );
  }

  reverseJob(body: Job): Observable<Job> {
    const endpoint = '/job/reverse';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärendet kunde inte hämtas'));
      })
    );
  }

  getJobs(page: number, size: number): Observable<Job[]> {
    const endpoint = '/jobs';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString())
    return this.http.get<Job[]>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        if (response && response.length) {
          const code = response[0].fetchStatus.split(':')[0];
          if (code === 'OK') {
            return response.map(job => new Job(job));
          } else {
            throw (new Error(code));
          }
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärenden kunde inte hämtas'));
      })
    );
  }

  getJobsByUser(body: User): Observable<Job[]> {
    const endpoint = '/jobs/user';
    return this.http.post<Job[]>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        if (response && response.length) {
          const code = response[0].fetchStatus.split(':')[0];
          if (code === 'OK') {
            return response.map(user => new Job(user));
          } else {
            throw (new Error(code));
          }
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärenden kunde inte hämtas'));
      })
    );
  }

  getArchivedJobs(date1: string, date2: string, customerId: string): Observable<Job[]> {
    const body = {
      startDate: date1,
      endDate: date2,
      customerID: customerId
    };
    const endpoint = '/billing/customer';
    return this.http.post<any>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        if (response.status !== 'OK') {
          throw (new Error(response.message));
        }
        return response.result.map(data => new Job(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Arkiverade ärenden kunde inte hämtas'));
      })
    );
  }

  getCollectedData(id: string): Observable<CollectedData> {
    const endpoint = '/collecteddata/';
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint + id, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new CollectedData(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte hämta inmatad data'));
      })
    );
  }

  getCollectedDataList(): Observable<CollectedData[]> {
    const endpoint = '/collecteddata/';
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new CollectedData(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte hämta inmatad data'));
      })
    );
  }

  updateCollectedData(body: CollectedData): Observable<CollectedData> {
    const endpoint = "/collecteddata/";
    return this.http.put<BkgLambdaResponse>(this.baseUrl + endpoint + body.id, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new CollectedData(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte spara inmatad data'));
      })
    );
  }

  getCollectedDataByJobId(id: string): Observable<CollectedData> {
    const endpoint1 = '/jobs/';
    const endpoint2 = '/collecteddata';
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint1 + id + endpoint2, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new CollectedData(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error(`Kunde inte hämta inmatad data för ärende: ${id}`));
      })
    );
  }

  postDataPart(body: DataPart): Observable<DataPart> {
    const endpoint = '/datapart';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new DataPart(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Data kunde inte sparas'));
      })
    );
  }

  getDataPart(body: DataPart): Observable<DataPart> {
    const endpoint = '/datapart/populate';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new DataPart(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Data kunde inte hämtas'));
      })
    );
  }

  getDataParts(jobId: String): Observable<DataPart[]> {
    const endpoint = `/jobs/${jobId}/dataparts`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        if (response && response.result.length) {
          const lambdaResponse = new BkgLambdaResponse(response);
          if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
            throw (new Error(response.message));
          }
          return lambdaResponse.result[0].map(data => new DataPart(data));
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Data kunde inte hämtas'));
      })
    );
  }

  getBulkdataActaPublica(body: Job): Observable<any[]> {
    const endpoint = '/bulkdata/actapublica';
    return this.http.post<any>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        if (response.message === 'OK') {
          return response.records;
        } else {
          throw (new Error(response.message));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Acta Publica data kunde inte hämtas'));
      })
    );
  }

  getCreditSafePerson(body: Job, level: string): Observable<CreditSafePerson> {
    const endpoint = '/bulkdata/creditsafe';
    const params = new HttpParams()
      .append('level', level.toString());
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new CreditSafePerson(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte hämta kredituppgifter'));
      })
    );
  }

  reportPreview(body: Job): Observable<Report> {
    const endpoint = '/report/preview';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Report(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Förhandsvisning kunde inte hämtas'));
      })
    );
  }

  postJobReport(jobId: string): Observable<object> {
    const endpoint = `/jobs/${jobId}/report`;
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, {}, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte färdigställa rapport'));
      })
    );
  }

  uploadReport(orderId: string, report: UploadReportBody): Observable<object> {
    const endpoint = `/protective-security/orders/${orderId}/reports`
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, report, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte ladda upp rapport'));
      })
    );
  }

  reportStatus(body: Job): Observable<Report> {
    const endpoint = '/report';
    return this.http.post<Job>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        return new Report(response);
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Rapport kunde inte hämtas'));
      })
    );
  }

  reportFinal(body: Job): Observable<Report> {
    const endpoint = '/report/final';
    return this.http.post<Report>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.message;
        if (code === 'OK') {
          return new Report(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Rapport kunde inte hämtas'));
      })
    );
  }

  reportDelivered(body: Job): Observable<Report> {
    const endpoint = '/report/delivered';
    return this.http.post<Report>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.message;
        if (code === 'OK') {
          return new Report(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Rapport kunde inte markeras som levererad'));
      })
    );
  }

  reviewPreview(body: Job): Observable<Report> {
    const endpoint = '/review/preview';
    return this.http.post<Report>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.message;
        if (code === 'OK') {
          return new Report(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Förhandsvisning kunde inte hämtas'));
      })
    );
  }

  reportFinalAsImages(body: Job): Observable<Report> {
    const endpoint = '/report/finalasimages';
    return this.http.post<Report>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.message;
        if (code === 'OK') {
          return new Report(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Rapport kunde inte hämtas'));
      })
    );
  }

  postAttachment(body: Attachment): Observable<Attachment> {
    const endpoint = '/attachment';
    return this.http.post<Attachment>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Attachment(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Fil kunde inte sparas'));
      })
    );
  }

  postAttachmentPopulate(body: Attachment): Observable<Attachment> {
    const endpoint = '/attachment/populate';
    return this.http.post<Attachment>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Attachment(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Fil kunde inte hämtas'));
      })
    );
  }

  postAttachmentDelete(body: Attachment): Observable<Attachment> {
    const endpoint = '/attachment/delete';
    return this.http.post<Attachment>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Attachment(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Fil kunde inte raderas'));
      })
    );
  }

  postAttachmentList(body: Attachment): Observable<Attachment[]> {
    const endpoint = '/attachment/list';
    return this.http.post<Attachment[]>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        if (response && response.length) {
          const code = response[0].fetchStatus.split(':')[0];
          if (code === 'OK') {
            return response.map(data => new Attachment(data));
          } else {
            throw (new Error(code));
          }
        }
        return [];
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Filer kunde inte hämtas'));
      })
    );
  }

  postBankIdAuthQueued(body: Consent): Observable<Consent> {
    const endpoint = '/bankid/auth-queued';
    return this.http.post<Consent>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const code = response.fetchStatus.split(':')[0];
        if (code === 'OK') {
          return new Consent(response);
        } else {
          throw (new Error(code));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        let message = 'Anslutningen till BankID misslyckades';
        if (error.message === '70021') {
          message = 'BankId session redan öppen';
        } else if (error.message === '70022') {
          message = 'Samtycke redan genomfört';
        }
        throw (new Error(message));
      })
    );
  }

  postBankIdAuthResult(body: Consent): Observable<Consent> {
    const endpoint = '/bankid/auth-result';
    return this.http.post<Consent>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        return new Consent(response);
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        let message = 'Anslutningen till BankID misslyckades';
        if (error.message === '70203') {
          message = error.message;
        }
        throw (new Error(message));
      })
    );
  }

  postNewAudit(body) {
    const endpoint = '/audit';
    return this.http.post<any>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        return new Audit(response);
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error(error.message));
      })
    );
  }

  getAudits(): Observable<Audit[]> {
    const endpoint = '/audit/all';
    return this.http.get<any>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(data => data.map(audit => new Audit(audit))
      ),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error(error.message));
      })
    );
  }

  getAuditsByUser(body: User): Observable<Audit[]> {
    const endpoint = '/audit/user';
    return this.http.post<any>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(data => data.map(audit => new Audit(audit))
      ),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error(error.message));
      })
    );
  }

  setAuditResultRead(body: SetAuditResultReadClass): Observable<Boolean> {
    const endpoint = '/audit/resultread';
    return this.http.post<any>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error(error.message));
      })
    );
  }

  getSpiListCoordinator(page: number, size: number): Observable<Job[]> {
    const endpoint = '/spi-coordinators/jobs';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('SPI-Lista kunde inte hämtas'));
      })
    );
  }

  getSpiListLeader(page: number, size: number): Observable<Job[]> {
    const endpoint = '/spi-leaders/jobs';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers, params: params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('SPI-Lista kunde inte hämtas'));
      })
    );
  }

  postSpiLeader(body: Job): Observable<Job> {
    const endpoint = '/spi-coordinators/updateSpiLeader';
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärendet kunde inte sparas'));
      })
    );
  }

  finalizeSpiReport(jobId: string): Observable<object> {
    const endpoint = `/spi-leaders/jobs/${jobId}/spi-report/final`;
    const params = new HttpParams()
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, {}, { headers: this.headers, params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte färdigställa rapport, OBS intervjurapport måste bifogas'));
      })
    );
  }

  postSpiReport(jobId: string, report: Report): Observable<object> {
    const endpoint = `/spi-leaders/jobs/${jobId}/spi-report`;
    const params = new HttpParams()
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, report, { headers: this.headers, params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte färdigställa rapport'));
      })
    );
  }

  postSpiAttachment(jobId: string, report: Report): Observable<object> {
    const endpoint = `/spi-leaders/jobs/${jobId}/spi-attachment`;
    const params = new HttpParams()
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, report, { headers: this.headers, params }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte lägga till bilaga'));
      })
    );
  }

  getSpiReport(jobId: string): Observable<Report> {
    const endpoint = `/jobs/${jobId}/spi-report`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        return lambdaResponse.result.map(data => new Report(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Spi-rapport kunde inte hämtas'));
      })
    );
  }

  getSpiAttachment(jobId: string): Observable<Report> {
    const endpoint = `/jobs/${jobId}/spi-attachment`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        return lambdaResponse.result.map(data => new Report(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Bilaga till SPI kunde inte hämtas'));
      })
    );
  }

  postScheduleOrder(orderId: String, scheduledOrder: Schedule): Observable<object> {
    const endpoint = `/orders/${orderId}/scheduled-order`;
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, scheduledOrder, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte sparas'));
      })
    );
  }

  getScheduleOrder(orderId: String): Observable<Schedule> {
    const endpoint = `/orders/${orderId}/scheduled-order`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Schedule(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte hämtas'));
      })
    );
  }

  deleteScheduleOrder(orderId: String): Observable<object> {
    const endpoint = `/orders/${orderId}/scheduled-order`;
    return this.http.delete<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte sparas'));
      })
    );
  }

  postScheduleFinalize(jobId: String, scheduledJob: Schedule): Observable<object> {
    const endpoint = `/report/${jobId}/scheduled-finalize`;
    return this.http.post<BkgLambdaResponse>(this.baseUrl + endpoint, scheduledJob, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte sparas'));
      })
    );
  }

  getScheduleFinalize(jobId: String): Observable<Schedule> {
    const endpoint = `/report/${jobId}/scheduled-finalize`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Schedule(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte hämtas'));
      })
    );
  }

  deleteScheduleFinalize(jobId: String): Observable<object> {
    const endpoint = `/report/${jobId}/scheduled-finalize`;
    return this.http.delete<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Schemaläggning kunde inte sparas'));
      })
    );
  }

  getRiscJob(jobId: string): Observable<JobRiscGrades> {
    const endpoint = `/jobs/${jobId}/risc`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new JobRiscGrades(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Risc värden kunde inte hämtas'));
      })
    );
  }

  approveScheduledSc(orderId: String): Observable<Job> {
    const endpoint = `/protective-security/orders/${orderId}/schedule-approval`;
    return this.http.put<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte godkänna aktivering av USÄK'));
      })
    );
  }

  updateRecipients(orderId: String, body: string[]): Observable<BkgLambdaResponse> {
    const endpoint = `/orders/${orderId}`;
    return this.http.patch<BkgLambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte uppdatera mottagare'));
      })
    );
  }

  getProtectiveSecurityList(status: string): Observable<Order[]> {
    const endpoint = '/protective-security/orders';
    const params = new HttpParams()
      .append("status", status);
    return this.http.get<Order[]>(this.baseUrl + endpoint, { params: params }).pipe(
      map(response => {
        if (response && response.length) {
          return response.map(order => new Order(order));
        }
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Kunde inte hämta USÄK-lista'));
      })
    );
  }

  getPsPersons(): Observable<PsPerson[]> {
    const endpoint = '/protective-security/persons';
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new PsPerson(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Personer kunde inte hämtas'));
      })
    );
  }

  getPsPerson(personId: string): Observable<PsPerson> {
    const endpoint = `/protective-security/persons/${personId}`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new PsPerson(data)).shift();
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Person kunde inte hämtas'));
      })
    );
  }

  updatePsPerson(personId: String, psPerson: PsPerson): Observable<object> {
    const endpoint = `/protective-security/persons/${personId}`;
    return this.http.put<any>(this.baseUrl + endpoint, psPerson, { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Person kunde inte sparas'));
      })
    );
  }

  getJobsForPerson(personId: string): Observable<Job[]> {
    const endpoint = `/persons/${personId}/jobs`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Job(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärenden för person kunde inte hämtas'));
      })
    );
  }

  getOrdersForPerson(personId: string): Observable<Order[]> {
    const endpoint = `/persons/${personId}/orders`;
    return this.http.get<BkgLambdaResponse>(this.baseUrl + endpoint, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new BkgLambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw (new Error(response.message));
        }
        return lambdaResponse.result.map(data => new Order(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw (new Error('Ärenden för person kunde inte hämtas'));
      })
    );
  }
}
