import { Deserializable } from '@models/deserializable.model';

export class CreditSafeAddressHistoricalData implements Deserializable {
  PNR: string;
  CO_ADDRESS: string;
  STREET: string;
  ZIPCODE: string;
  TOWN: string;
  ADDRESS_TYPE: string;
  FROM_DATE: string;
  TO_DATE: string;

  constructor(input?: any) {
    this.PNR = '';
    this.CO_ADDRESS = '';
    this.STREET = '';
    this.ZIPCODE = '';
    this.TOWN = '';
    this.ADDRESS_TYPE = '';
    this.FROM_DATE = '';
    this.TO_DATE = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
