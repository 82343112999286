import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {
  @Input() url: string;
  @Input() text: string;

  constructor() { }

  ngOnInit() {
    if (!(this.url.indexOf('http://') > -1 || this.url.indexOf('https://') > -1)) {
      this.url = 'http://' + this.url;
    }
  }

  onClick(event: Event) {
    event.preventDefault();
    if (!this.url) { return; }

    window.open(this.url);
  }
}
