import { Pipe, PipeTransform } from '@angular/core';
import { CollectedDataStatus } from '@enums/collected-data-status.enum';

@Pipe({
  name: 'swedishTranslationyCollectedData'
})
export class SwedishTranslationCollectedDataPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      // Collected data status
      case CollectedDataStatus.CREATED: return 'Ej påbörjad';
      case CollectedDataStatus.SAVED: return 'Påbörjad';
      case CollectedDataStatus.DONE: return 'Färdigställd';

      default: return value;
    }
  }
}
