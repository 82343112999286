import { RefSurveyQuestion } from '@models/ref-survey-question.model';
import { Deserializable } from '@models/deserializable.model';

export class RefSurvey implements Deserializable {
  private static TYPE_SURVEY = 'com.sri.ref.backend.dto.Survey';
  private static TYPE_TEMPLATE = 'com.sri.ref.backend.dto.SurveyTemplate';

  public id: string;
  public classType: string;
  public customerId: string;
  public orderId: string;
  public title: string;
  public description: string;
  public publicSurvey: boolean;
  public surveyQuestions: RefSurveyQuestion[];

  constructor(input?: any) {
    this.id = null;
    this.classType = null;
    this.customerId = null;
    this.orderId = null;
    this.title = '';
    this.description = '';
    this.publicSurvey = false;
    this.surveyQuestions = [];

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.surveyQuestions) {
      this.surveyQuestions = input.surveyQuestions.map(question => new RefSurveyQuestion(question));
    }
    return this;
  }

  configureAsSurvey() {
    this.classType = RefSurvey.TYPE_SURVEY;
  }

  configureAsTemplate() {
    this.classType = RefSurvey.TYPE_TEMPLATE;
    this.orderId = null;
  }

  getQuestionCount(): number {
    if (!this.surveyQuestions) {
      return 0;
    }
    return this.surveyQuestions.length;
  }

  validate(): string {
    if (!this.title) { return 'Titel saknas'; }
    if (!this.description) { return 'Beskrivning saknas'; }
    if (!this.surveyQuestions || (this.surveyQuestions && !this.surveyQuestions.length)) { return 'Frågor saknas'; }

    for (const question of this.surveyQuestions) {
      const message = question.validate();
      if (message !== null) { return message; }
    }

    return null;
  }

  isTypeSurvey(): boolean {
    return this.classType === RefSurvey.TYPE_SURVEY;
  }

  isTypeTemplate(): boolean {
    return this.classType === RefSurvey.TYPE_TEMPLATE;
  }
}
