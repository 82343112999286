import { Deserializable } from '@models/deserializable.model';
import { RefReferenceState } from '../enums/ref-reference-state.enum';

export class RefReferenceStateLog implements Deserializable {
  time: Date;
  state: RefReferenceState;

  constructor(input?: any) {
    this.time = null;
    this.state = null;

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
