import { Deserializable } from '@models/deserializable.model';
import { RefQuestionType } from 'src/app/shared/enums/ref-question-type.enum';

export class RefSurveyQuestion implements Deserializable {
  public type: RefQuestionType;
  public mandatory: boolean;
  public question: string;
  public options: string[];

  constructor(input?: any) {
    // this.type = RefQuestionType.FREETEXT;
    this.type = null;
    this.mandatory = false;
    this.question = '';
    this.options = [];

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getOptionsCount(): number {
    if (this.options === null) { return 0; }
    return this.options.length;
  }

  validate(): string {
    if (!this.question) { return 'Fråga saknar frågetext'; }
    if(!this.type) { return 'Fråga saknar typ'; }

    if (this.type === RefQuestionType.OPTIONS) {
      if (this.getOptionsCount() < 1) { return 'Fråga saknar svarsalternativ'; }
      for (const option of this.options) {
        if (!option) { return 'Fråga innehåller tomma svarsalternativ'; }
      }
    }

    return null;
  }

  isTypeNull(): boolean { return this.type === null; }
  isTypeOptions(): boolean { return this.type === RefQuestionType.OPTIONS; }
  isTypeSlider(): boolean { return this.type === RefQuestionType.SLIDER; }
  isTypeFreetext(): boolean { return this.type === RefQuestionType.FREETEXT; }
}
