import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from '@environments/environment.prod';
import { User } from '@models/user.model';
import { AuthService } from '@services/auth.service';

class PortalLink {
  path: string;
  text: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class PortalComponent implements OnInit {
  @Input() user: User;
  @Input() currentPath: string;
  portalLinks: PortalLink[];
  environment = environment;


  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.setupPortalLinks();
  }

  setupPortalLinks() {
    if (!this.user) {
      console.error('Missing user');
      return;
    }

    this.portalLinks = [];
    if (this.user.isAdmin()) {
      this.portalLinks.push({ path: 'admin', text: 'Admin' });
    }
    if (this.user.isCoordinator() || this.user.isSpiCoordinator()) {
      this.portalLinks.push({ path: 'coordinator', text: 'Koordinator' });
    }
    if (this.user.isAnalyst()) {
      this.portalLinks.push({ path: 'analyst', text: 'Analytiker' });
    }
    if (this.user.isCustomerAdmin()) {
      this.portalLinks.push({ path: 'customer-admin', text: 'Kundadmin' });
    }
    if (this.user.hasServiceBackgroundCheck()) {
      this.portalLinks.push({ path: 'background-check', text: 'Bakgrundskontroller' });
    }
    if (this.user.hasLfd()) {
      this.portalLinks.push({ path: 'lfd', text: 'LFD' });
    }
    if (this.user.hasServiceReferenceCheck()) {
      this.portalLinks.push({ path: 'reference-check', text: 'Referenstagning' });
    }
    if (this.user.isDataCollector()) {
      this.portalLinks.push({ path: 'data-collector', text: 'Inmatningskö' });
    }
    if (this.user.isSpiLeader()) {
      this.portalLinks.push({ path: 'spi', text: 'SPI' });
    }
    if (this.user.hasServiceProtectiveSecurity()) {
      this.portalLinks.push({ path: 'sak', text: 'SÄK' });
    }
  }

  onGotoAccount(event: Event) {
    event.preventDefault();
    this.router.navigate(['account']);
  }

  onLogout(event: Event) {
    event.preventDefault();
    this.auth.logout();
    this.router.navigate(['login']);
  }

  onGotoPortal(event: Event, path: string) {
    event.preventDefault();
    this.router.navigate([path]);
  }

  isActivePortal(path: string) {
    return this.currentPath === path;
  }
}
