import { Deserializable } from '@models/deserializable.model';

export class RefFile implements Deserializable {
  id: string;
  classType: string;
  name: string;
  data: string;
  type: string;

  constructor(input?: any) {
    this.id = null;
    this.classType = 'com.sri.ref.backend.dto.File';
    this.name = null;
    this.data = null;
    this.type = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
