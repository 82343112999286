import { Component, Input, OnInit } from '@angular/core';
import { User } from '@models/user.model';
import { UserPermission, UserPermissionHelperService } from '@services/user-permission-helper.service';

@Component({
  selector: 'app-user-permissions-list',
  templateUrl: './user-permissions-list.component.html',
  styleUrls: ['./user-permissions-list.component.css']
})
export class UserPermissionsListComponent implements OnInit {
  @Input() user: User;
  showPermissions: boolean = false;
  permissions: UserPermission[];

  constructor(private permissionHelper: UserPermissionHelperService) {
  }

  ngOnInit() {
    this.permissions = this.permissionHelper.getPermissions();
    this.permissions = this.permissionHelper.toggleActivePermissions(this.permissions, this.user);
  }

  togglePermissions() {
    this.showPermissions = !this.showPermissions;
  }

  togglePermissionChildren(permission: UserPermission) {
    permission.toggle = !permission.toggle;
  }
}
