import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserGroupId } from '@enums/user-group-id.enum';
import { AuthService } from '@services/auth.service';
import { UserHelperService } from '@services/user-helper.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userHelper: UserHelperService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = next.data.roles;
    return this.isAuthorized(roles);
  }

  isAuthorized(roles: UserGroupId[]): Observable<boolean> {
    return new Observable((observer) => {
      this.auth.isLoggedInAsync().subscribe(isLoggedIn => {
        let isAuthorized = false;
        if (isLoggedIn) {
          const user = this.auth.getUserFromToken();
          let inGroup = false;

          if (roles.length === 0) {
            inGroup = true;
          } else {
            for (const role of roles) {
              if (user.isInUserGroup(role)) {
                inGroup = true;
                break;
              }
            }
          }

          if (inGroup) {
            isAuthorized = true;
          } else {
            console.error('Not authorized! Redirecting...');
            const route = this.userHelper.getUserLandingPageRoute(user);
            this.router.navigate([route]);
          }
        } else {
          console.error('Not authorized! Redirecting...');
          this.router.navigate(['login']);
        }

        observer.next(isAuthorized);
        observer.complete();
      });
    });
  }
}
