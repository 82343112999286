import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@services/data.service';

@Pipe({
  name: 'userEmail'
})
export class UserEmailPipe implements PipeTransform {

  constructor(private data: DataService) { }

  transform(userId: string): any {
    if (!userId) { return userId; }

    for (const user of this.data.getCustomerUsers()) {
      if (user.userId === userId) { return user.userEmail; }
    }
    return userId.substr(0, 8).toUpperCase();
  }
}
