import { Deserializable } from '@models/deserializable.model';
import { CreditSafeAddressHistoricalData } from './creditsafe-address-historical-data.model';
import { CreditSafeCompanyEngagementsData } from './creditsafe-company-engagements-data.model';
import { CreditSafeDetailRealEstateData } from './creditsafe-detail-real-estate-data.model';
import { CreditSafeDetailRealEstateExtendedData } from './creditsafe-detail-real-estate-extended-data-model';
import { CreditSafeDistrainRepossessionClaimsData } from './creditsafe-distrain-repossession-claims-data.model';
import { CreditSafeHistoricalDebtDetailsData } from './creditsafe-historical-debt-details-data.model';
import { CreditSafeRopPrivateClaimsData } from './creditsafe-rop-private-claims-data.model';
import { CreditSafeRopPublicClaimsData } from './creditsafe-rop-public-claims-data.model';

export class CreditSafePerson implements Deserializable {
  addressHistoricalData: CreditSafeAddressHistoricalData[];
  companyEngagementsData: CreditSafeCompanyEngagementsData[];
  detailRealEstateData: CreditSafeDetailRealEstateData[];
  detailRealEstateExtendedData: CreditSafeDetailRealEstateExtendedData[];
  distrainRepossessionClaimsData: CreditSafeDistrainRepossessionClaimsData[];
  historicalDebtDetailsData: CreditSafeHistoricalDebtDetailsData[];
  ropPrivateClaimsData: CreditSafeRopPrivateClaimsData[];
  ropPublicClaimsData: CreditSafeRopPublicClaimsData[];

  PNR: string;
  FIRST_NAME: string;
  GIVEN_NAME: string;
  LAST_NAME: string;
  CO_ADDRESS: string;
  REGISTERED_ADDRESS: string;
  ADDRESS: string;
  ZIPCODE: string;
  TOWN: string;
  COMMUNITY: string;
  FORSAMLING: string;
  FORSAMLINGNO: string;
  RELATION: string;
  ROP_SUM: string;
  ROP_NUMBER: string;
  BANKRUPTCY: string;
  DEBT_SUM: string;
  DEBT_DATE: string;
  DEBT_AMAL_NUMBER: string;
  DEBT_EMAL_NUMBER: string;
  DEBT_AMAL_SUM: string;
  DEBT_EMAL_SUM: string;
  DEBT_NUMBER: string;
  DEBT_PERSON: string;
  SCORING: string;
  COMMON_REDUCE: string;
  TEXEBLE_INCOME: string;
  INCOME_CAPITAL: string;
  DEFICIT_CAPITAL: string;
  EARNED_INCOME_AND_BUSINESS: string;
  TOTAL_INCOME: string;
  FINAL_TAX: string;
  VAT: string;
  INVESTIGATE: string;
  TAX_YEAR: string;
  DATE_LAST_AMAL: string;
  DATE_LAST_EMAL: string;
  TYPE_AMAL: string;
  HAS_TRUSTEE: string;
  SPEC_CO_ADDRESS: string;
  SPEC_ADDRESS: string;
  SPEC_ZIPCODE: string;
  SPEC_COUNTRY: string;
  SPEC_TOWN: string;
  SPEC_REGISTERED_ADDRESS: string;
  NUMBER_REALESTATE: string;
  NUMBER_BUILDINGS: string;
  ASSESSED_VALUE_TOTAL: string;
  AVERAGE_OWNED_PART_PERCENT: string;
  ASSESSED_VALUE_BUILDING: string;
  ASSESSED_VALUE_LAND: string;
  ASSESSED_VALUE_OWNED_PART: string;
  SEARCH_DATE: string;
  BUSINESS_OWNER: string;
  SERIOUS_REMARK: string;
  ROP_NUMBER1: string;
  SERIOUS_REMARK1: string;
  INVESTIGATE_NUMBER: string;
  EARNED_INCOME_AND_BUSINESS2: string;
  TOTAL_INCOME2: string;
  FINAL_TAX2: string;
  COUNTY: string;
  COUNTYNO: string;
  COMMUNITYNO: string;
  EMIGRATED: string;
  EMIGRATED_DATE: string;
  BANKRUPTCY_TYPE: string;
  BANKRUPTCY_DATE: string;
  BANKRUPTCY_TEXT: string;
  PROTECTED: string;
  RELATION_NAME: string;
  ROP_SUM_AMAL: string;
  ROP_NUMBER_AMAL: string;
  ROP_SUM_EMAL: string;
  ROP_NUMBER_EMAL: string;
  GENDER: string;
  DEBTRELIEF_TYPE: string;
  DEBTRELIEF_TEXT: string;
  DEBTRELIEF_DATE: string;
  BANKRUPTCY_ADM_NAME: string;
  BANKRUPTCY_ADM_FIRM: string;
  BANKRUPTCY_ADM_PHONENODIR: string;
  BANKRUPTCY_ADM_PHONENOCENTRAL: string;
  BANKRUPTCY_ADM_VISIT_ADDRESS: string;
  BANKRUPTCY_ADM_BOX_ADDRESS: string;
  BANKRUPTCY_ADM_POST_CODE: string;
  BANKRUPTCY_ADM_POST_TOWN: string;
  ROP_NUMBER_AMAL1: string;
  ROP_NUMBER_EMAL1: string;
  AGE: string;
  CIVIL_STATE_CODE: string;
  CIVIL_STATE_TEXT: string;
  GUARDIAN1_PNR: string;
  GUARDIAN1_NAME: string;
  GUARDIAN2_PNR: string;
  GUARDIAN2_NAME: string;
  LOSS_FROM_ACTIVE_BUSINESS: string;
  LOSS_FROM_PASSIVE_BUSINESS: string;
  DETAILREALESTATE: string;
  DETAILREALESTATE_EXTENDED: string;
  ROP_PRIVATE_CLAIMS: string;
  ROP_PUBLIC_CLAIMS: string;
  DISTRAINT_REPOSSESSION_CLAIMS: string;
  ADDRESS_HISTORICAL: string;
  PREVIOUS_NAME: string;
  TEXEBLE_INCOME2: string;
  LATE_FEE_TAX: string;
  TAX_PENALTY: string;
  LATE_FEE_TAX2: string;
  TAX_PENALTY2: string;
  DATE_REGISTERED_IN_SWEDEN: string;
  HISTORICAL_DEBT_DETAILS: string;
  COMPANY_ENGAGEMENTS: string;
  INCOME_CAPITAL2: string;
  DEFICIT_CAPITAL2: string;
  COMMON_REDUCE2: string;
  ROP_SUM_AMAL1: string;
  ROP_SUM_EMAL1: string;
  EARNED_INCOME_AND_BUSINESS3: string;
  TOTAL_INCOME3: string;
  FINAL_TAX3: string;
  INCOME_CAPITAL3: string;
  DEFICIT_CAPITAL3: string;
  COMMON_REDUCE3: string;
  TEXEBLE_INCOME3: string;
  INCOME: string;
  INCOME_BUSINESS_ACTIVE: string;
  INCOME_BUSINESS: string;
  INCOME2: string;
  INCOME_BUSINESS_ACTIVE2: string;
  INCOME_BUSINESS2: string;
  INCOME3: string;
  INCOME_BUSINESS_ACTIVE3: string;
  INCOME_BUSINESS3: string;

  constructor(input?: any) {
    this.addressHistoricalData = [];
    this.companyEngagementsData = [];
    this.detailRealEstateData = [];
    this.detailRealEstateExtendedData = [];
    this.distrainRepossessionClaimsData = [];
    this.historicalDebtDetailsData = [];
    this.ropPrivateClaimsData = [];
    this.ropPublicClaimsData = [];

    this.PNR = '';
    this.FIRST_NAME = '';
    this.GIVEN_NAME = '';
    this.LAST_NAME = '';
    this.CO_ADDRESS = '';
    this.REGISTERED_ADDRESS = '';
    this.ADDRESS = '';
    this.ZIPCODE = '';
    this.TOWN = '';
    this.COMMUNITY = '';
    this.FORSAMLING = '';
    this.FORSAMLINGNO = '';
    this.RELATION = '';
    this.ROP_SUM = '';
    this.ROP_NUMBER = '';
    this.BANKRUPTCY = '';
    this.DEBT_SUM = '';
    this.DEBT_DATE = '';
    this.DEBT_AMAL_NUMBER = '';
    this.DEBT_EMAL_NUMBER = '';
    this.DEBT_AMAL_SUM = '';
    this.DEBT_EMAL_SUM = '';
    this.DEBT_NUMBER = '';
    this.DEBT_PERSON = '';
    this.SCORING = '';
    this.COMMON_REDUCE = '';
    this.TEXEBLE_INCOME = '';
    this.INCOME_CAPITAL = '';
    this.DEFICIT_CAPITAL = '';
    this.EARNED_INCOME_AND_BUSINESS = '';
    this.TOTAL_INCOME = '';
    this.FINAL_TAX = '';
    this.VAT = '';
    this.INVESTIGATE = '';
    this.TAX_YEAR = '';
    this.DATE_LAST_AMAL = '';
    this.DATE_LAST_EMAL = '';
    this.TYPE_AMAL = '';
    this.HAS_TRUSTEE = '';
    this.SPEC_CO_ADDRESS = '';
    this.SPEC_ADDRESS = '';
    this.SPEC_ZIPCODE = '';
    this.SPEC_COUNTRY = '';
    this.SPEC_TOWN = '';
    this.SPEC_REGISTERED_ADDRESS = '';
    this.NUMBER_REALESTATE = '';
    this.NUMBER_BUILDINGS = '';
    this.ASSESSED_VALUE_TOTAL = '';
    this.AVERAGE_OWNED_PART_PERCENT = '';
    this.ASSESSED_VALUE_BUILDING = '';
    this.ASSESSED_VALUE_LAND = '';
    this.ASSESSED_VALUE_OWNED_PART = '';
    this.SEARCH_DATE = '';
    this.BUSINESS_OWNER = '';
    this.SERIOUS_REMARK = '';
    this.ROP_NUMBER1 = '';
    this.SERIOUS_REMARK1 = '';
    this.INVESTIGATE_NUMBER = '';
    this.EARNED_INCOME_AND_BUSINESS2 = '';
    this.TOTAL_INCOME2 = '';
    this.FINAL_TAX2 = '';
    this.COUNTY = '';
    this.COUNTYNO = '';
    this.COMMUNITYNO = '';
    this.EMIGRATED = '';
    this.EMIGRATED_DATE = '';
    this.BANKRUPTCY_TYPE = '';
    this.BANKRUPTCY_DATE = '';
    this.BANKRUPTCY_TEXT = '';
    this.PROTECTED = '';
    this.RELATION_NAME = '';
    this.ROP_SUM_AMAL = '';
    this.ROP_NUMBER_AMAL = '';
    this.ROP_SUM_EMAL = '';
    this.ROP_NUMBER_EMAL = '';
    this.GENDER = '';
    this.DEBTRELIEF_TYPE = '';
    this.DEBTRELIEF_TEXT = '';
    this.DEBTRELIEF_DATE = '';
    this.BANKRUPTCY_ADM_NAME = '';
    this.BANKRUPTCY_ADM_FIRM = '';
    this.BANKRUPTCY_ADM_PHONENODIR = '';
    this.BANKRUPTCY_ADM_PHONENOCENTRAL = '';
    this.BANKRUPTCY_ADM_VISIT_ADDRESS = '';
    this.BANKRUPTCY_ADM_BOX_ADDRESS = '';
    this.BANKRUPTCY_ADM_POST_CODE = '';
    this.BANKRUPTCY_ADM_POST_TOWN = '';
    this.ROP_NUMBER_AMAL1 = '';
    this.ROP_NUMBER_EMAL1 = '';
    this.AGE = '';
    this.CIVIL_STATE_CODE = '';
    this.CIVIL_STATE_TEXT = '';
    this.GUARDIAN1_PNR = '';
    this.GUARDIAN1_NAME = '';
    this.GUARDIAN2_PNR = '';
    this.GUARDIAN2_NAME = '';
    this.LOSS_FROM_ACTIVE_BUSINESS = '';
    this.LOSS_FROM_PASSIVE_BUSINESS = '';
    this.DETAILREALESTATE = '';
    this.DETAILREALESTATE_EXTENDED = '';
    this.ROP_PRIVATE_CLAIMS = '';
    this.ROP_PUBLIC_CLAIMS = '';
    this.DISTRAINT_REPOSSESSION_CLAIMS = '';
    this.ADDRESS_HISTORICAL = '';
    this.PREVIOUS_NAME = '';
    this.TEXEBLE_INCOME2 = '';
    this.LATE_FEE_TAX = '';
    this.TAX_PENALTY = '';
    this.LATE_FEE_TAX2 = '';
    this.TAX_PENALTY2 = '';
    this.DATE_REGISTERED_IN_SWEDEN = '';
    this.HISTORICAL_DEBT_DETAILS = '';
    this.COMPANY_ENGAGEMENTS = '';
    this.INCOME_CAPITAL2 = '';
    this.DEFICIT_CAPITAL2 = '';
    this.COMMON_REDUCE2 = '';
    this.ROP_SUM_AMAL1 = '';
    this.ROP_SUM_EMAL1 = '';
    this.EARNED_INCOME_AND_BUSINESS3 = '';
    this.TOTAL_INCOME3 = '';
    this.FINAL_TAX3 = '';
    this.INCOME_CAPITAL3 = '';
    this.DEFICIT_CAPITAL3 = '';
    this.COMMON_REDUCE3 = '';
    this.TEXEBLE_INCOME3 = '';
    this.INCOME = '';
    this.INCOME_BUSINESS_ACTIVE = '';
    this.INCOME_BUSINESS = '';
    this.INCOME2 = '';
    this.INCOME_BUSINESS_ACTIVE2 = '';
    this.INCOME_BUSINESS2 = '';
    this.INCOME3 = '';
    this.INCOME_BUSINESS_ACTIVE3 = '';
    this.INCOME_BUSINESS3 = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.addressHistoricalData) {
      this.addressHistoricalData = input.addressHistoricalData.map(addressHistoricalData =>
        new CreditSafeAddressHistoricalData(addressHistoricalData));
    }
    if (input.companyEngagementsData) {
      this.companyEngagementsData = input.companyEngagementsData.map(companyEngagementsData =>
        new CreditSafeCompanyEngagementsData(companyEngagementsData));
    }
    if (input.detailRealEstateData) {
      this.detailRealEstateData = input.detailRealEstateData.map(detailRealEstateData =>
        new CreditSafeDetailRealEstateData(detailRealEstateData));
    }
    if (input.detailRealEstateExtendedData) {
      this.detailRealEstateExtendedData = input.detailRealEstateExtendedData.map(detailRealEstateExtendedData =>
        new CreditSafeDetailRealEstateData(detailRealEstateExtendedData));
    }
    if (input.distrainRepossessionClaimsData) {
      this.distrainRepossessionClaimsData = input.distrainRepossessionClaimsData.map(distrainRepossessionClaimsData =>
        new CreditSafeDistrainRepossessionClaimsData(distrainRepossessionClaimsData));
    }
    if (input.historicalDebtDetailsData) {
      this.historicalDebtDetailsData = input.historicalDebtDetailsData.map(historicalDebtDetailsData =>
        new CreditSafeHistoricalDebtDetailsData(historicalDebtDetailsData));
    }
    if (input.ropPrivateClaimsData) {
      this.ropPrivateClaimsData = input.ropPrivateClaimsData.map(ropPrivateClaimsData =>
        new CreditSafeRopPrivateClaimsData(ropPrivateClaimsData));
    }
    if (input.ropPublicClaimsData) {
      this.ropPublicClaimsData = input.ropPublicClaimsData.map(ropPublicClaimsData =>
        new CreditSafeRopPublicClaimsData(ropPublicClaimsData));
    }
    return this;
  }

  isMissingOrZero(data: string): boolean {
    return !data || data === '' || data === '0';
  }

  numberWithSpaces(data: string): string {
    return data.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
