import { Deserializable } from './deserializable.model';

export class Audit implements Deserializable {
  id: string;
  ssn: string;
  userId: string;
  dumpId: string;
  searchTime: string;
  motivation: string;
  numResults: number;
  results: AuditResult[];
  fetchStatus: string;

    constructor(input?: any) {
      this.id = null;
      this.ssn = null;
      this.userId = null;
      this.dumpId = null;
      this.searchTime = null;
      this.motivation = null;
      this.numResults = null;
      this.results = null;
      this.fetchStatus = null;
        
        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}

export class AuditResult {
    id: string;
    jobId: string;
    dumpReport: string;
    reportIsRead: boolean;
}

export class SetAuditResultReadClass implements Deserializable {
    dumpId: string;
    jobId: string;

    constructor(input?: any) {
        this.dumpId = null;
        this.jobId = null;
        if (input) { this.deserialize(input); }
    }
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}
