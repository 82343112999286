export class Pager {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startItem: number;
  endItem: number;
  pages: number[];

  constructor() {
    this.totalItems = 0;
    this.currentPage = 0;
    this.pageSize = 0;
    this.totalPages = 0;
    this.startPage = 0;
    this.endPage = 0;
    this.startItem = 0;
    this.endItem = 0;
    this.pages = [];
  }
}
