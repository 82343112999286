import { Deserializable } from '@models/deserializable.model';

export class LambdaResponse implements Deserializable {
  public statusCode: number;
  public message: string;
  public result: any[];

  constructor(input?: any) {
    this.statusCode = 0;
    this.message = '';
    this.result = [];

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  isSuccessfull(): boolean {
    return this.statusCode === 200;
  }
}
