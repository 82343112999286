import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-dots',
  template: '{{dots}}'
})
export class LoadingDotsComponent implements OnInit {
  dots = '';
  interval = 300;

  ngOnInit() {
    this.tick();
  }

  tick() {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
    setTimeout(() => {
      this.tick();
    }, this.interval);
  }
}
