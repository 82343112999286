import { Component, OnInit } from '@angular/core';
import { DataService } from '@services/data.service';
import { User } from '@models/user.model';
import { Customer } from '@models/customer.model';
import { HttpBackgroundCheckService } from '@services/http-background-check.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  user: User;
  customer: Customer;
  customerLoading = false;
  customerError: string;

  constructor(
    private data: DataService,
    private http: HttpBackgroundCheckService
  ) { }

  ngOnInit() {
    this.user = this.data.getUser();
    this.getCustomer();
  }

  getCustomer() {
    this.customerLoading = true;
    this.customerError = null;
    const cust = new Customer();
    cust.id = this.user.custNo;
    this.http.getCustomer(cust).subscribe(response => {
      this.customerLoading = false;
      this.customer = response;
    }, error => {
      this.customerLoading = false;
      this.customerError = error.message;
    });
  }

  isCustomerVisible(): boolean {
    return !this.customerLoading && !this.customerError;
  }
}
