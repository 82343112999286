import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Pager } from '@models/pager.model';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {
  @Input() pager: Pager;
  @Input() locked: boolean;
  @Output() setPage = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {}

  onSetPage(event: Event, page: number) {
    event.preventDefault();
    this.setPage.emit(page);
  }

  onPreviousPage(event: Event) {
    event.preventDefault();
    if (this.isPreviousPageDisabled()) { return; }

    const page = this.pager.currentPage - 1;
    this.setPage.emit(page);
  }

  onNextPage(event: Event) {
    event.preventDefault();
    if (this.isNextPageDisabled()) { return; }

    const page = this.pager.currentPage + 1;
    this.setPage.emit(page);
  }

  isPagerVisible(): boolean {
    return this.pager.totalPages > 1;
  }

  isCurrentPage(page: number): boolean {
    return this.pager.currentPage === page;
  }

  isPreviousPageDisabled(): boolean {
    return this.pager.currentPage <= 1;
  }

  isNextPageDisabled(): boolean {
    return this.pager.currentPage >= this.pager.totalPages;
  }
}
