import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserGroupId } from '@enums/user-group-id.enum';
import { Customer } from '@models/customer.model';
import { DataService } from '@services/data.service';
import { HttpBackgroundCheckService } from '@services/http-background-check.service';
import { UserPermission, UserPermissionHelperService } from '@services/user-permission-helper.service';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.css']
})

export class FilterDropdownComponent implements OnInit {
  @Output() filteredUserGroupIdList = new EventEmitter<UserGroupId[]>();
  @Input() loading = false;
  @Input() sriStaff = false;
  @Input() customerAdminView = false;

  error: string;
  customer: Customer;
  permissions: UserPermission[];
  userGroupIdList: UserGroupId[] = [];

  constructor(
    private permissionHelper: UserPermissionHelperService,
    private http: HttpBackgroundCheckService,
    private data: DataService) { }

  ngOnInit() {
    this.getCustomer();
  }

  getCustomer() {
    this.loading = true;
    this.error = null;
    const cust = new Customer();
    cust.id = this.data.getUser().custNo;
    this.http.getCustomer(cust).subscribe(response => {
      this.customer = response;
      this.getPermissions();
    }, error => {
      this.loading = false;
      this.error = error.message;
    });
  }

  getPermissions() {
    this.permissions = this.permissionHelper.getPermissions();

    if (!this.customerAdminView) {
      if (!this.sriStaff) {
        this.permissions = this.permissionHelper.filterSriOnlyPermissions(this.permissions);
      }
    }
    else {
      this.permissions = this.permissionHelper.filterSriOnlyPermissions(this.permissions);
      this.permissions = this.permissionHelper.filterFailedRequirements(this.permissions, this.customer);
    }
  }

  togglePermissionChildren(permission: UserPermission) {
    permission.toggle = !permission.toggle;
  }

  togglePermissionChildrenClick(permission: UserPermission) {
    if (!permission.active && !permission.toggle) {
      this.togglePermissionChildren(permission)
    }
  }

  getFilteredPermissionsList() {
    this.userGroupIdList = [];
    this.updateUserGroupIdList(this.permissions);

    this.filteredUserGroupIdList.emit(this.userGroupIdList);
  }

  updateUserGroupIdList(permissions: UserPermission[]) {
    for (const permission of permissions) {
      if (permission.active) {
        this.userGroupIdList.push(permission.name);
      }

      this.updateUserGroupIdList(permission.children);
    }
  }

  clearPermissions() {
    this.setInactive(this.permissions);
    this.getFilteredPermissionsList();
  }

  setInactive(permissions: UserPermission[]) {
    for (let permission of permissions) {
      permission.active = false;
      this.setInactive(permission.children);
    }
  }
}