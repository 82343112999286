import { Deserializable } from '@models/deserializable.model';

export class RefPollStatus implements Deserializable {
  classType: string;
  id: string;
  status: string;

  constructor(input?: any) {
    this.id = null;
    this.status = null;
    this.classType = 'com.sri.ref.backend.dto.PollStatus';

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
