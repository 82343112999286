import { JobState } from '@enums/job-state.enum';
import { Deserializable } from '@models/deserializable.model';

export class JobStateLog implements Deserializable {
  time: Date;
  state: JobState;
  assignedAnalyst: String;


  constructor(input?: any) {
    this.time = null;
    this.state = null;
    this.assignedAnalyst = null;

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}