import { Deserializable } from '@models/deserializable.model';
import { RefReferenceState } from '../enums/ref-reference-state.enum';
import { RefReferenceStateLog } from './ref-reference-state-log.model';
import { RefReferenceInfo } from './ref-reference-info.model';
import { RefSurveyAnswer } from './ref-survey-answer.model';

export class RefReference implements Deserializable {
  id: string;
  key: string;
  classType: string;
  orderId: string;
  surveyId: string;
  state: RefReferenceState;
  stateLog: RefReferenceStateLog[];
  referenceInfo: RefReferenceInfo;
  surveyAnswers: RefSurveyAnswer[];
  declineReason: string;
  shadowed: boolean;
  noVerification: boolean;

  constructor(input?: any) {
    this.id = null;
    this.key = null;
    this.classType = 'com.sri.ref.backend.dto.Reference';
    this.orderId = null;
    this.surveyId = null;
    this.state = null;
    this.stateLog = [];
    this.referenceInfo = new RefReferenceInfo();
    this.surveyAnswers = [];
    this.declineReason = null;
    this.shadowed = false;
    this.noVerification = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.referenceInfo) {
      this.referenceInfo = new RefReferenceInfo(input.referenceInfo);
    }
    if (input.stateLog) {
      this.stateLog = input.stateLog.map(stateLog => new RefReferenceStateLog(stateLog));
    }
    if (input.surveyAnswers) {
      this.surveyAnswers = input.surveyAnswers.map(answer => new RefSurveyAnswer(answer));
    }

    return this;
  }

  validate(): string {
    const message = this.referenceInfo.validate();
    if (message !== null) { return message; }

    return null;
  }

  isCreated(): boolean {
    return this.state === RefReferenceState.CREATED;
  }

  isDeclined(): boolean {
    return this.state === RefReferenceState.DECLINED;
  }

  isPending(): boolean {
    return this.state === RefReferenceState.PENDING;
  }

  isDone(): boolean {
    return this.state === RefReferenceState.DONE;
  }

  isTimedout(): boolean {
    return this.state === RefReferenceState.TIMEOUT;
  }
}
