import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {
  public objectsMatch(obj1: object, obj2: object): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public static transformID(id: string): any {
    if (id === null) { return id; }
    if (!id.includes("-")) { return id; }

    const shortGuid = id.substring(0, id.indexOf("-"));

    if (!shortGuid.length) { return id; }

    return shortGuid.toUpperCase();
  }
}
