import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ref-scale',
  templateUrl: './ref-scale.component.html',
  styleUrls: ['./ref-scale.component.css']
})
export class RefScaleComponent implements OnInit {
  @Input() value = 0;
  @Input() locked = true;
  @Output() change = new EventEmitter<number>();
  maxValue = 10;
  values = [];

  constructor() { }

  ngOnInit() {
    this.initValues();
  }

  initValues() {
    for (let i = 1; i <= this.maxValue; i++) {
      this.values.push(i);
    }
  }

  onChange(newValue: number) {
    this.value = newValue;
    this.change.emit(newValue);
  }

  isCurrentValue(value: number): boolean {
    return this.value === value;
  }
}
