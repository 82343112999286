import { Deserializable } from '@models/deserializable.model';

export class CreditSafeDetailRealEstateData implements Deserializable {
  PNR: string;
  REALESTATE_DESCRIPTION: string;
  PROPERTY_TYPE: string;
  NUMBER_OWNERS: string;
  OWNED_PART_PERCENT: string;
  AREAL: string;
  COUNTY: string;
  COMMUNITY: string;
  PARISH: string;

  constructor(input?: any) {
    this.PNR = '';
    this.REALESTATE_DESCRIPTION = '';
    this.PROPERTY_TYPE = '';
    this.NUMBER_OWNERS = '';
    this.OWNED_PART_PERCENT = '';
    this.AREAL = '';
    this.COUNTY = '';
    this.COMMUNITY = '';
    this.PARISH = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
