import { Pipe, PipeTransform } from '@angular/core';
import { SecurityClassStatus } from '@enums/security-class-state.enum';
import { SecurityClassClearance } from '@enums/security-level.enum';

@Pipe({
  name: 'swedishTranslationSecurityClass'
})
export class SwedishTranslationSecurityClass implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      // SecurityClassStatus
      case SecurityClassStatus.PENDING: return 'Inväntar';
      case SecurityClassStatus.DENIED: return 'Nekad';
      case SecurityClassStatus.APPROVED: return 'Godkänd';
      case SecurityClassStatus.REVOKED: return 'Återkallad';

      // SecurityClassClearance
      case SecurityClassClearance.NO_LEVEL: return 'Ej inplacerad i säkerhetsklass';
      case SecurityClassClearance.LEVEL1: return 'Säkerhetsklass 1';
      case SecurityClassClearance.LEVEL2: return 'Säkerhetsklass 2';
      case SecurityClassClearance.LEVEL3: return 'Säkerhetsklass 3';
      default: return value;
    }
  }
}
