import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PopupService } from '@services/popup.service';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { Observable, Observer } from 'rxjs';
import { UserGroup } from 'src/app/shared/models/user-group.model';
import { User } from 'src/app/shared/models/user.model';
import Swal from 'sweetalert2';
import { DataService } from './data.service';
import { Router } from '@angular/router';

export interface LoginCallback {
  loginCallback(message: string, success: boolean): void;
}

export interface ForgotPasswordCallback {
  forgotPasswordCallback(message: string, success: boolean): void;
}

export interface IsLoggedInCallback {
  isLoggedInCallback(message: string, loggedIn: boolean): void;
}

@Injectable()
export class AuthService {
  private poolData = {
    UserPoolId: environment.userPoolId,
    ClientId: environment.appClientId,
    UserNameAttributes: 'email',
    Paranoia: 7
  };
  private userPool: CognitoUserPool;
  private idToken: CognitoIdToken;
  private accessToken: CognitoAccessToken;
  private refreshToken: CognitoRefreshToken;
  private swal = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-secondary'
    },
    buttonsStyling: false,
    showClass: {
      backdrop: 'swal2-noanimation',
      popup: '',
      icon: ''
    },
    hideClass: {
      popup: ''
    }
  });

  constructor(
    private popup: PopupService,
    private data: DataService,
    private router: Router
  ) {
    this.getUserPool();
  }

  private getUserPool() {
    this.userPool = new CognitoUserPool(this.poolData);
  }

  private getCognitoUser(username: string): CognitoUser {
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    return new CognitoUser(userData);
  }

  private getAutDetails(username: string, password: string): AuthenticationDetails {
    const authData = {
      Username: username,
      Password: password
    };
    return new AuthenticationDetails(authData);
  }

  getIdTokenAsJwt(): string {
    return (this.idToken) ? this.idToken.getJwtToken() : '';
  }

  getAccessTokenAsJwt(): string {
    return (this.accessToken) ? this.accessToken.getJwtToken() : '';
  }

  isLoggedIn(callback: IsLoggedInCallback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser === null) {
      callback.isLoggedInCallback('Can\'t retrieve current user', false);
      return;
    }

    cognitoUser.getSession((error, session: CognitoUserSession) => {
      if (error) {
        this.clearTokensAndData();
        callback.isLoggedInCallback(error, false);
      } else {
        this.setTokensAndUser(session);
        callback.isLoggedInCallback(null, session.isValid());
      }
    });
  }

  isLoggedInAsync(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        this.handleInvalidSession(observer);
        return;
      }

      cognitoUser.getSession((error, session: CognitoUserSession) => {
        if (error || !session.isValid()) {
          this.handleInvalidSession(observer);
          return;
        }

        this.setTokensAndUser(session);
        observer.next(true);
        observer.complete();
      });
    });
  }

  private handleInvalidSession(observer: Observer<boolean>): void {
    this.router.navigate(['login']);
    this.clearTokensAndData();
    observer.next(false);
    observer.complete();
  }

  private setTokensAndUser(session: CognitoUserSession) {
    this.idToken = session.getIdToken();
    this.accessToken = session.getAccessToken();
    this.refreshToken = session.getRefreshToken();

    this.data.setUser(this.getUserFromToken());
  }

  private clearTokensAndData() {
    this.idToken = null;
    this.accessToken = null;
    this.refreshToken = null;

    this.data.clear();
  }

  getUserFromToken(): User {
    const user = new User();

    if (this.idToken === null) {
      return user;
    }
    const decodedToken = this.idToken.decodePayload();
    user.userId = decodedToken['cognito:username'];
    user.userEmail = decodedToken.email;
    user.userPhonenumber = decodedToken.phone_number;
    user.custNo = decodedToken['custom:customer_number'];
    user.costCenter = decodedToken['custom:costcenter'];

    const userGroups: UserGroup[] = [];
    for (const group of decodedToken['cognito:groups']) {
      const userGroup = new UserGroup();
      userGroup.userGroupId = group;
      userGroups.push(userGroup);
    }
    user.userGroups = userGroups;

    return user;
  }

  login(username: string, password: string, callback: LoginCallback) {
    const auth = this;
    const cognitoUser = this.getCognitoUser(username);
    const authDetails = this.getAutDetails(username, password);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess(session) {
        auth.setTokensAndUser(session);
        callback.loginCallback(null, true);
      },
      onFailure(err) {
        const message = auth.getCognitoErrorMessage(err.code);
        callback.loginCallback(message, false);
      },
      mfaRequired() {
        auth.popup.code((code: string) => {
          cognitoUser.sendMFACode(code, this);
        });
      },
      newPasswordRequired(userAttributes) {
        auth.swal.fire({
          title: 'Skapa nytt lösenord',
          text: 'Du behöver skapa nytt lösenord vid första inloggningen',
          html:
            `<div class="alert alert-primary" style="text-align:left">
              <label><i class="material-icons">info</i> Lösenordskrav:</label>
              <ul>
                <li>Minst 8 tecken</li>
                <li>Minst en stor bokstav</li>
                <li>Minst en liten bokstav</li>
                <li>Minst en siffra</li>
                <li>Minst ett specialtecken<br>(ex: @ $ ! % * ? &)</li>
              </ul>
            </div>`,
          confirmButtonText: 'Bekräfta',
          input: 'password'
        }).then((result1) => {
          const pw1 = result1.value;
          if (auth.isValidPassword(pw1)) {
            auth.swal.fire({
              title: 'Upprepa lösenord',
              input: 'password'
            }).then((result2) => {
              const pw2 = result2.value;
              if (pw1 === pw2) {
                delete userAttributes.email_verified;
                delete userAttributes.email
                cognitoUser.completeNewPasswordChallenge(pw1, { email: userAttributes.email }, this);
              } else {
                auth.swal.fire('Fel', 'Lösenorden matchade inte', 'error');
              }
            });
          } else {
            auth.swal.fire('Ogiltigt lösenord', '', 'error');
          }
        });
      }
    });
  }

  logout() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
      this.clearTokensAndData();
    }
  }

  forgotPassword(username: string, newPassword: string, callback: ForgotPasswordCallback) {
    const auth = this;
    const cognitoUser = this.getCognitoUser(username);

    cognitoUser.forgotPassword({
      onSuccess() {
        callback.forgotPasswordCallback(null, true);
      },
      onFailure(err) {
        const message = auth.getCognitoErrorMessage(err.name);
        callback.forgotPasswordCallback(message, false);
      },
      inputVerificationCode() {
        auth.swal.fire({
          title: 'Verifiera kod från e-post',
          input: 'text',
          confirmButtonText: 'Verifiera'
        }).then((result) => {
          cognitoUser.confirmPassword(result.value, newPassword, {
            onSuccess() {
              auth.swal.fire({
                title: 'Lösenord ändrat',
                text: 'Du kan nu logga in med det nya lösenordet',
                confirmButtonText: 'Okej'
              }).then(() => {
                callback.forgotPasswordCallback(null, true);
              });
            },
            onFailure(err) {
              const message = auth.getCognitoErrorMessage(err.name);
              callback.forgotPasswordCallback(message, false);
            }
          });
        });
      }
    });
  }

  isValidPassword(password: string) {
    const reg = new RegExp('^(?=.*[a-zA-ZäöåÄÖÅ])(?=.*\\d)(?=.*[@$!%*?&])[a-zA-ZäöåÄÖÅ\\d@$!%*?&]{8,99}$');
    return reg.test(password);
  }

  private getCognitoErrorMessage(code: string): string {
    switch (code) {
      case 'UserNotFoundException': return 'Användaren existerar inte';
      case 'NotAuthorizedException': return 'Fel email eller lösenord';
      case 'CodeMismatchException': return 'Fel SMSkod';
      case 'TooManyRequestsException': return 'För många försök. Försök igen senare';
      default: return 'Inloggning misslyckades';
    }
  }
}
