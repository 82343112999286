import { Deserializable } from '@models/deserializable.model';
import { RefCandidateInfo } from '@models/ref-candidate-info.model';
import { RefOrderState } from 'src/app/shared/enums/ref-order-state.enum';
import { RefOrderStateLog } from './ref-order-state-log.model';

export class RefOrder implements Deserializable {
  id: string;
  classType: string;
  key: string;
  surveyId: string;
  state: RefOrderState;
  stateLog: RefOrderStateLog[];
  customerId: string;
  customerOrderer: string;
  customerRecipients: string[];
  company: string;
  companyHidden: boolean;
  signatureHidden: boolean;
  position: string;
  positionURL: string;
  description: string;
  candidateInfo: RefCandidateInfo;
  minReferences: number;
  maxReferences: number;
  noVerification: boolean;

  constructor(input?: any) {
    this.id = null;
    this.classType = 'com.sri.ref.backend.dto.Order';
    this.key = null;
    this.surveyId = null;
    this.state = null;
    this.stateLog = [];
    this.customerId = null;
    this.customerOrderer = null;
    this.customerRecipients = [];
    this.company = '';
    this.companyHidden = false;
    this.signatureHidden = false;
    this.position = '';
    this.positionURL = null;
    this.description = null;
    this.candidateInfo = new RefCandidateInfo();
    this.minReferences = 1;
    this.maxReferences = 5;
    this.noVerification = false;

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.candidateInfo) {
      this.candidateInfo = new RefCandidateInfo(input.candidateInfo);
    }
    if (input.stateLog) {
      this.stateLog = input.stateLog.map(stateLog => new RefOrderStateLog(stateLog));
    }
    return this;
  }

  validate(): string {
    if (!this.company) { return 'Bolagsnamn saknas'; }
    if (!this.position) { return 'Tjänst saknas'; }
    if (this.minReferences < 1) { return 'Min antal referenser ogiltigt'; }
    if (this.maxReferences > 10) { return 'Max antal referenser ogiltigt'; }
    if (this.minReferences > this.maxReferences) { return 'Min/max antal referenser ogiltigt'; }
    if (!this.surveyId) { return 'Frågebatteri saknas'; }
    if (!(this.customerRecipients && this.customerRecipients.length)) { return 'Mottagare saknas'; }

    const message = this.candidateInfo.validate();
    if (message !== null) { return message; }

    return null;
  }

  isCreated(): boolean { return this.state === RefOrderState.CREATED; }
  isOrdered(): boolean { return this.state === RefOrderState.ORDERED; }
  isSurveying(): boolean { return this.state === RefOrderState.SURVEYING; }
  isDone(): boolean { return this.state === RefOrderState.DONE; }
  isTimedout(): boolean { return this.state === RefOrderState.TIMEOUT; }
}
