import { Deserializable } from '@models/deserializable.model';
import { VehicleOwnership } from '@models/vehicle-ownership.model';
import { DriversLicense } from './drivers-license.model';

export class CollectedDataDetails implements Deserializable {
    verifyEducation: string;
    verifyEmployer: string;
    extraQuestion: string;
    debtHistorySixRegister: string;
    citizenship: string;
    other: string;
    driversLicenseComment: string;
    vehicleOwnership: VehicleOwnership[];
    driversLicense: DriversLicense[];

    constructor(input?: any) {
        this.verifyEducation = '';
        this.verifyEmployer = '';
        this.extraQuestion = '';
        this.debtHistorySixRegister = '';
        this.citizenship = '';
        this.other = '';
        this.driversLicenseComment = '';
        this.vehicleOwnership = [];
        this.driversLicense = [];

        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        if (input.vehicleOwnership && input.vehicleOwnership instanceof Array) {
            this.vehicleOwnership = input.vehicleOwnership.map(vehicleOwnership => new VehicleOwnership(vehicleOwnership));
            this.driversLicense = input.driversLicense.map(driversLicense => new DriversLicense(driversLicense));
        }
        return this;
    }
}