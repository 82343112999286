import { Injectable } from '@angular/core';
import { Customer } from '@models/customer.model';
import { RefSettings } from '@models/ref-settings.model';
import { User } from '@models/user.model';

@Injectable()
export class DataService {
  private user: User = null;
  private customer: Customer = null;
  private refSettings: RefSettings = null;
  private analysts: User[] = [];
  private spiLeaders: User[] = [];
  private customerUsers: User[] = [];
  private customers: Customer[] = [];

  constructor() { }

  // User is automatically populated by AuthService on successful authorization
  getUser(): User {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
  }

  getCustomer(): Customer {
    return this.customer;
  }

  setCustomer(customer: Customer) {
    this.customer = customer;
  }

  getRefSettings(): RefSettings {
    return this.refSettings;
  }

  setRefSettings(settings: RefSettings) {
    this.refSettings = settings;
  }

  getAnalysts(): User[] {
    return this.analysts;
  }

  setAnalysts(analysts: User[]) {
    this.analysts = analysts;
  }

  getCustomerUsers(): User[] {
    return this.customerUsers;
  }

  setCustomerUsers(users: User[]) {
    this.customerUsers = users;
  }

  getCustomers(): Customer[] {
    return this.customers;
  }

  setCustomers(customers: Customer[]) {
    this.customers = customers;
  }

  getSpiLeaders(): User[] {
    return this.spiLeaders;
  }

  setSpiLeaders(spiLeaders: User[]) {
    this.spiLeaders = spiLeaders;
  }

  clear() {
    this.user = null;
    this.customer = null;
    this.refSettings = null;
    this.analysts = [];
    this.spiLeaders = [];
    this.customerUsers = [];
    this.customers = [];
  }
}
