import { Deserializable } from './deserializable.model';

export class Assignment implements Deserializable {
  fromUser: string;
  toUser: string;
  time: string;
  fetchStatus: string;
  modified: string;

  constructor(input?: any) {
    this.fromUser = null;
    this.toUser = null;
    this.time = null;
    this.fetchStatus = null;
    this.modified = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
