import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BankIDHintCode as HintCode } from '@enums/bankid-hint-code.enum';
import { BankIDStatus as Status } from '@enums/bankid-status.enum';
import { BankIDUserMessage as UserMessage } from '@enums/bankid-user-message.enum';
import { GetBankIDResultResponse, StartBankIDResponse } from '@models/bankid.model';
import { Observable } from 'rxjs';

enum State {
  SELECT,
  PROCESSING,
  COMPLETE,
  ERROR
}

@Component({
  selector: 'app-bankid',
  templateUrl: './bankid.component.html',
  styleUrls: ['./bankid.component.css']
})
export class BankIdComponent {
  @Input() startFunction: (mobileDevice: boolean, sameDevice: boolean) => Observable<StartBankIDResponse>;
  @Input() pollFunction: (orderRef: string) => Observable<GetBankIDResultResponse>;
  @Output() complete = new EventEmitter();
  @Output() cancel = new EventEmitter();

  state = State.SELECT;
  pollInterval = 2000;
  qrPollInterval = 1000;
  timeout = false;
  pollTimeout = 300000;
  info: string;
  error: string;
  useQRCode = false;
  qrCode: string = null;

  constructor() { }

  cancelBankID() {
    this.cancel.emit()
  }

  reset() {
    this.state = State.SELECT;
    this.timeout = false;
    this.info = null;
    this.error = null;
    this.qrCode = null;
  }

  startBankID(useQRCode: boolean) {
    this.state = State.PROCESSING;
    this.useQRCode = useQRCode;
    this.info = 'Startar...';

    this.startFunction(this.isMobile(), !this.useQRCode).subscribe(response => {
      if (!response) {
        this.handleFailed();
        return;
      }

      if (this.useQRCode) {
        this.qrCode = response.qrCode;
      } else {
        this.launchBankIDApp(response.autoStartToken);
      }

      this.info = this.getPendingUserMessage('');
      this.schedulePollResult(response.orderRef);
      setTimeout(() => this.activateTimeout(), this.pollTimeout);
    }, () => {
      this.handleFailed();
    });
  }

  launchBankIDApp(autoStartToken: string) {
    const launchUrlMobile = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    const launchUrlDesktop = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;

    window.location.href = this.isMobile() ? launchUrlMobile : launchUrlDesktop;
  }

  activateTimeout() {
    if (this.isStateError()) {
      return;
    }

    this.state = State.ERROR;
    this.timeout = true;
    this.error = this.getFailedUserMessage('');
  }

  schedulePollResult(orderRef: string) {
    const interval = this.useQRCode ? this.qrPollInterval : this.pollInterval;
    setTimeout(() => this.pollResult(orderRef), interval);
  }

  pollResult(orderRef: string) {
    if (this.timeout) {
      return;
    }

    this.pollFunction(orderRef).subscribe(response => {
      if (!response) {
        this.handleFailed();
        return;
      }

      switch (response.status) {
        case Status.COMPLETE: this.handleComplete(); break;
        case Status.PENDING: this.handlePending(response.hintCode, response.qrCode, orderRef); break;
        case Status.FAILED: // Intentional fall through to default
        default: this.handleFailed(response.hintCode);
      }
    }, () => {
      this.handleFailed();
    });
  }

  handleComplete() {
    this.state = State.COMPLETE;
    this.info = 'Klart!';
    this.complete.emit();
  }

  handlePending(hintCode: string, qrCode: string, orderRef: string) {
    this.info = this.getPendingUserMessage(hintCode);
    this.qrCode = this.useQRCode ? qrCode : null;
    this.schedulePollResult(orderRef);
  }

  handleFailed(hintCode?: string) {
    this.state = State.ERROR;
    this.error = this.getFailedUserMessage(hintCode ? hintCode : '');
  }

  getPendingUserMessage(hintCode: string): string {
    switch (hintCode) {
      case HintCode.OUTSTANDING_TRANSACTION: return UserMessage.RFA13;
      case HintCode.NO_CLIENT: return UserMessage.RFA1;
      case HintCode.STARTED: return UserMessage.RFA15A;
      case HintCode.USER_MRTD: return UserMessage.RFA23;
      case HintCode.USER_CALL_CONFIRM: return UserMessage.RFA24;
      case HintCode.USER_SIGN: return UserMessage.RFA9;
      default: return UserMessage.RFA21;
    }
  }

  getFailedUserMessage(hintCode: string): string {
    switch (hintCode) {
      case HintCode.EXPIRED_TRANSACTION: return UserMessage.RFA8;
      case HintCode.CERTIFICATE_ERR: return UserMessage.RFA16;
      case HintCode.USER_CANCEL: return UserMessage.RFA6;
      case HintCode.CANCELLED: return UserMessage.RFA3;
      case HintCode.START_FAILED: return UserMessage.RFA17A;
      default: return UserMessage.RFA22;
    }
  }

  isMobile = () => /android|iPhone|iPad|huawei/i.test(navigator.userAgent);
  isStateSelect = () => this.state === State.SELECT;
  isStateProcessing = () => this.state === State.PROCESSING;
  isStateComplete = () => this.state === State.COMPLETE;
  isStateError = () => this.state === State.ERROR;
}
