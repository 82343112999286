import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@services/data.service';

@Pipe({
  name: 'customerName'
})
export class CustomerNamePipe implements PipeTransform {

  constructor(
    private data: DataService
  ) { }

  transform(customerId: string): any {
    for (const customer of this.data.getCustomers()) {
      if (customer.custNo === customerId) { return customer.custName; }
    }
    return customerId;
  }

}
