import { Injectable } from '@angular/core';
import { CustomerAccess } from '@enums/customer-access.enum';
import { Customer } from '@models/customer.model';

export class CustomerPermission {
  name: CustomerAccess;
  children: CustomerPermission[];
  active: boolean;

  constructor(name: CustomerAccess, children?: CustomerPermission[]) {
    this.name = name;
    this.children = children ? children : [];
    this.active = false;
  }
}

@Injectable()
export class CustomerPermissionHelperService {
  private permissions: CustomerPermission[] = [
    new CustomerPermission(CustomerAccess.BackgroundCheck),
    new CustomerPermission(CustomerAccess.ReferenceCheck),
    new CustomerPermission(CustomerAccess.LFD),
    new CustomerPermission(CustomerAccess.ProtectiveSecurity, [
      new CustomerPermission(CustomerAccess.SecurityScreening),
      new CustomerPermission(CustomerAccess.SecurityClearance)
    ])
  ];

  constructor() { }

  getCustomerPermissions(customer: Customer): CustomerPermission[] {
    const permissions: CustomerPermission[] = JSON.parse(JSON.stringify(this.permissions));
    this.setCustomerPermissions(customer, permissions)

    return permissions;
  }

  private setCustomerPermissions(customer: Customer, permissions: CustomerPermission[]) {
    for (const permission of permissions) {
      permission.active = customer.access.some(access => access === permission.name)
      this.setCustomerPermissions(customer, permission.children);
    }
  }
}
