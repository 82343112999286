import { Deserializable } from "./deserializable.model";

export class Document implements Deserializable {
  id: string;
  refId: string;
  refType: string;
  group: string;
  type: string;
  format: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;

  constructor(input?: any) {
    this.id = null;
    this.refId = null;
    this.refType = null;
    this.group = null;
    this.type = null;
    this.format = null;
    this.createdDate = null;
    this.createdBy = null;
    this.updatedDate = null;
    this.updatedBy = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}