import { JobScheduleType } from "@enums/job-schedule-type.enum";
import { Deserializable } from "./deserializable.model";

export class JobSchedule implements Deserializable {
    scheduleId: string;
    activationTime: string;
    scheduleType: JobScheduleType;

    constructor(input?: any) {
        this.scheduleId = null;
        this.activationTime = null;
        this.scheduleType = null;

        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}