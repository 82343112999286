import { Assignment } from "./assignment.model";
import { Deserializable } from "./deserializable.model";

export class JobSpiDetails implements Deserializable {
    spiLeaderId: string;
    date: string;
    comment: string;
    reporting: string;
    assignments: Assignment[];
    refCheckId: string;
    spiType: string;

    constructor(input?: any) {
        this.spiLeaderId = null;
        this.date = null;
        this.comment = null;
        this.reporting = null;
        this.assignments = [];
        this.refCheckId = null;
        this.spiType = null;

        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}