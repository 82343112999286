import { Deserializable } from '@models/deserializable.model';
import { UserGroup } from '@models/user-group.model';
import { UserGroupId } from 'src/app/shared/enums/user-group-id.enum';

export class User implements Deserializable {
  public userId: string;
  public userEmail: string;
  public userPhonenumber: string;
  public costCenter: string;
  public userGroups: UserGroup[];
  public userStatus: string;
  public userEnabled: boolean;
  public userCreated: Date;
  public userUpdated: Date;
  public custNo: string;
  public fetchStatus: string;

  constructor(input?: any) {
    this.userId = null;
    this.userEmail = '';
    this.userPhonenumber = '';
    this.costCenter = '';
    this.userGroups = [];
    this.userStatus = null;
    this.userEnabled = true;
    this.userCreated = null;
    this.userUpdated = null;
    this.custNo = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  static withId(id: string): User {
    const user = new User();
    user.userId = id;

    return user;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.userGroups) {
      this.userGroups = input.userGroups.map(userGroup => new UserGroup(userGroup));
    }
    return this;
  }

  isAdmin(): boolean { return this.isInUserGroup(UserGroupId.USER_ADMIN); }
  isCoordinator(): boolean { return this.isInUserGroup(UserGroupId.COORDINATOR); }
  isAnalyst(): boolean { return this.isInUserGroup(UserGroupId.ANALYST); }
  isCustomerAdmin(): boolean { return this.isInUserGroup(UserGroupId.CUSTOMER_ADMIN); }
  isCustomerSuperuser(): boolean { return this.isInUserGroup(UserGroupId.CUSTOMER_SUPERUSER); }
  isDataCollector(): boolean { return this.isInUserGroup(UserGroupId.DATA_COLLECTOR); }
  isSpiLeader(): boolean { return this.isInUserGroup(UserGroupId.SPI_LEADER); }
  isSpiCoordinator(): boolean { return this.isInUserGroup(UserGroupId.SPI_COORDINATOR); }
  hasLfd(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_LFD); }
  hasLfdOrderer(): boolean { return this.isInUserGroup(UserGroupId.LFD_ORDERER); }
  hasLfdRecipient(): boolean { return this.isInUserGroup(UserGroupId.LFD_RECIPIENT); }
  hasLfdArchived(): boolean { return this.isInUserGroup(UserGroupId.LFD_ARCHIVED); }
  hasLfdScheduled(): boolean { return this.isInUserGroup(UserGroupId.LFD_SCHEDULED); }
  hasLfdDelete(): boolean { return this.isInUserGroup(UserGroupId.LFD_DELETE); }
  hasLfdDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.LFD_DOWNLOAD_REPORT) }
  hasServiceBackgroundCheck(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_BACKGROUND_CHECK); }
  hasBackgroundCheckOrderer(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_ORDERER); }
  hasBackgroundCheckOrdererScreen(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_ORDERER_SCREEN); }
  hasBackgroundCheckOrdererCoworker(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_ORDERER_COWORKER); }
  hasBackgroundCheckOrdererExecutive(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_ORDERER_EXECUTIVE); }
  hasBackgroundCheckOrdererSpecialist(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_ORDERER_SPECIALIST); }
  hasBackgroundCheckRecipient(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_RECIPIENT); }
  hasBackgroundCheckDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.BACKGROUND_CHECK_DOWNLOAD_REPORT); }
  hasServiceReferenceCheck(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_REFERENCE_CHECK); }
  hasReferenceCheckOrderer(): boolean { return this.isInUserGroup(UserGroupId.REFERENCE_CHECK_ORDERER); }
  hasReferenceCheckRecipient(): boolean { return this.isInUserGroup(UserGroupId.REFERENCE_CHECK_RECIPIENT); }
  hasReferenceCheckQuestionnaireCreator(): boolean { return this.isInUserGroup(UserGroupId.REFERENCE_CHECK_QUESTIONNAIRE_CREATOR); }
  hasReferenceCheckDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.REFERENCE_CHECK_DOWNLOAD_REPORT); }
  hasReferenceCheckDelete(): boolean { return this.isInUserGroup(UserGroupId.REFERENCE_CHECK_DELETE); }
  hasServiceProtectiveSecurity(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_PROTECTIVE_SECURITY); }
  hasServiceSecurityScreening(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_SECURITY_SCREENING); }
  hasSecurityScreeningOrdererBasic(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_SCREENING_ORDERER_BASIC); }
  hasSecurityScreeningOrderer(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_SCREENING_ORDERER); }
  hasSecurityScreeningRecipient(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_SCREENING_RECIPIENT); }
  hasSecurityScreeningDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_SCREENING_DOWNLOAD_REPORT); }
  hasSpiDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.SPI_DOWNLOAD_REPORT); }
  hasScreeningExtraServices(): boolean { return this.isInUserGroup(UserGroupId.SCREENING_EXTRA_SERVICES); }
  hasServiceSecurityClearance(): boolean { return this.isInUserGroup(UserGroupId.SERVICE_SECURITY_CLEARANCE); }
  hasSecurityClearanceApproveActivation(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_APPROVE_ACTIVATION); }
  hasSecurityClearanceArchived(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_ARCHIVED); }
  hasSecurityClearanceAssistantSecurityManager(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER); }
  hasSecurityClearanceDownloadReport(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_DOWNLOAD_REPORT); }
  hasSecurityClearanceOrderer(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_ORDERER); }
  hasSecurityClearanceRecipient(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_RECIPIENT); }
  hasSecurityClearanceManageSecurityCleared(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED); }
  hasSecurityClearanceScheduled(): boolean { return this.isInUserGroup(UserGroupId.SECURITY_CLEARANCE_SCHEDULED); }
  hasSecurityClearanceSecurityManager(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_SECURITY_MANAGER); }
  hasSecurityClearanceSecurityStaff(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_SECURITY_STAFF); }
  hasProtectiveSecurityOrderer(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_ORDERER); }
  hasProtectiveSecurityRecipient(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_RECIPIENT); }
  hasProtectiveSecurityChangeRecipient(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_CHANGE_RECIPIENT); }
  hasSecurityClearanceImportOrder(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_IMPORT_ORDER); }
  hasProtectiveSecurityEditSecurityClass(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS); }
  hasProtectiveSecurityEditTag(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_EDIT_TAG); }
  hasProtectiveSecurityDeleteTag(): boolean { return this.isInUserGroup(UserGroupId.PROTECTIVE_SECURITY_DELETE_TAG); }

  isSriStaff(): boolean {
    const staffUserGroups = [
      UserGroupId.USER_ADMIN,
      UserGroupId.COORDINATOR,
      UserGroupId.SPI_COORDINATOR,
      UserGroupId.SPI_LEADER,
      UserGroupId.ANALYST,
      UserGroupId.DATA_COLLECTOR
    ];

    return staffUserGroups.some(group => this.isInUserGroup(group));
  }

  isInUserGroup(groupId: string): boolean {
    if (!this.userGroups) { return false; }
    return this.userGroups.find(user => user.userGroupId === groupId) !== undefined;
  }

  getName(): string {
    if (this.userEmail) {
      return this.userEmail.split('@')[0];
    }
    return this.userEmail;
  }

  getFullName(): string {
    const parts: string[] = this.userEmail.split('@');

    const nameParts: string[] = parts[0].split('.');

    const formattedNameParts: string[] = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));

    const formattedName: string = formattedNameParts.join(' ');

    return formattedName;
  }

  isUserActive = (): boolean => {
    return this.userEnabled && this.userStatus === 'CONFIRMED';
  }
}
