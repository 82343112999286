import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGroupId } from '@enums/user-group-id.enum';
import { AccountComponent } from './core/components/account/account.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }, {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: { roles: [] }
  }, {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.USER_ADMIN] }
  }, {
    path: 'coordinator',
    loadChildren: () => import('./coordinator/coordinator.module').then(m => m.CoordinatorModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.COORDINATOR, UserGroupId.SPI_COORDINATOR] },
  }, {
    path: 'analyst',
    loadChildren: () => import('./analyst/analyst.module').then(m => m.AnalystModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.ANALYST] }
  }, {
    path: 'customer-admin',
    loadChildren: () => import('./customer-admin/customer-admin.module').then(m => m.CustomerAdminModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.CUSTOMER_ADMIN] }
  }, {
    path: 'background-check',
    loadChildren: () => import('./background-check/background-check.module').then(m => m.BackgroundCheckModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.SERVICE_BACKGROUND_CHECK] }
  }, {
    path: 'reference-check',
    loadChildren: () => import('./reference-check/reference-check.module').then(m => m.ReferenceCheckModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.SERVICE_REFERENCE_CHECK] }
  }, {
    path: 'data-collector',
    loadChildren: () => import('./data-collector/data-collector.module').then(m => m.DataCollectorModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.DATA_COLLECTOR] }
  }, {
    path: 'lfd',
    loadChildren: () => import('./lfd/lfd.module').then(m => m.LfdModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.SERVICE_LFD] }
  }, {
    path: 'spi',
    loadChildren: () => import('./spi/spi.module').then(m => m.SpiModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.SPI_LEADER] }
  }, {
    path: 'sak',
    loadChildren: () => import('./ps/ps.module').then(m => m.PsModule),
    canActivate: [AuthGuard],
    data: { roles: [UserGroupId.SERVICE_PROTECTIVE_SECURITY] }
  }, {
    path: 'external',
    loadChildren: () => import('./external/external.module').then(m => m.ExternalModule)
  }, {
    path: 'scs',
    loadChildren: () => import('./scs/scs.module').then(m => m.ScsModule)
  }, {
    path: 'consent/:id', // TODO: Legacy path? Unused?
    redirectTo: 'external/consent/:id',
    pathMatch: 'full'
  }, {
    path: 'portal',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: '404',
    component: NotFoundComponent
  }, {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }