import { Deserializable } from '@models/deserializable.model';
import { Gender } from 'src/app/shared/enums/gender.enum';

export class RefReferenceInfo implements Deserializable {
  name: string;
  relationship: string;
  email: string;
  phone: string;
  signedAge: number;
  signedGender: Gender;
  signedName: string;
  ip: string;

  constructor(input?: any) {
    this.name = '';
    this.relationship = '';
    this.email = '';
    this.phone = '';
    this.signedAge = 0;
    this.signedGender = Gender.MALE;
    this.signedName = '';
    this.ip = "";
    
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  validate(): string {
    if (!this.name) { return 'Namn saknas'; }
    if (!this.relationship) { return 'Relation saknas'; }
    if (!this.email) { return 'Email saknas'; }
    if (!this.isPhoneValid()) { return 'Telefonnummer ogiltigt'; }

    return null;
  }

  isPhoneValid(): boolean {
    if (!this.phone) { return false; }
    if (this.phone.substr(3, 1) === '0') { return false; }
    if (this.phone.length < 9) { return false; }
    if (this.phone.length > 17) { return false; }
    if (!Number.isInteger(Number(this.phone.substring(1)))) { return false; }
    if (this.phone.substring(0, 3) !== '+49' &&
      this.phone.substring(0, 3) !== '+47' &&
      this.phone.substring(0, 3) !== '+46' &&
      this.phone.substring(0, 3) !== '+45' &&
      this.phone.substring(0, 3) !== '+44' &&
      this.phone.substring(0, 3) !== '+35' &&
      this.phone.substring(0, 4) !== '+421' &&
      this.phone.substring(0, 3) !== '+31') {
      return false;
    }

    return true;
  }
}
