import { PType } from "@enums/p-type.enum";
import { Deserializable } from "./deserializable.model";

export class JobLFDDetails implements Deserializable {
    parentOrderId: string;
    sequenceNumber: number;
    followUp: boolean;
    followUpLfdCheckPoints: PType[];
    ScheduleID: string;
    activationTime: string;

    constructor(input?: any) {
        this.parentOrderId = null;
        this.sequenceNumber = 1;
        this.followUp = false;
        this.followUpLfdCheckPoints = [];
        this.ScheduleID = null;
        this.activationTime = null;

        if (input) { this.deserialize(input); }
      }

      deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}
