import { Component, OnInit, Input } from '@angular/core';
import { RefOrderState } from '@enums/ref-order-state.enum';

@Component({
  selector: 'app-ref-order-status',
  templateUrl: './ref-order-status.component.html',
  styleUrls: ['./ref-order-status.component.css']
})
export class RefOrderStatusComponent implements OnInit {
  @Input() state: RefOrderState;

  constructor() { }

  ngOnInit() { }

  isNull(): boolean { return !this.state; }
  isCreated(): boolean { return this.state === RefOrderState.CREATED; }
  isOrdered(): boolean { return this.state === RefOrderState.ORDERED; }
  isSurveying(): boolean { return this.state === RefOrderState.SURVEYING; }
  isDone(): boolean { return this.state === RefOrderState.DONE; }
  isTimedout(): boolean { return this.state === RefOrderState.TIMEOUT; }
}
