import { Injectable } from '@angular/core';
import { RefOrderHelperService } from '@services/ref-order-helper.service';
import { RefReport } from '@models/ref-report.model';

@Injectable()
export class RefReportHelperService {

  constructor(private orderHelper: RefOrderHelperService) { }

  sortByOrderDate(reports: RefReport[], ascending: boolean): RefReport[] {
    return reports.sort((a, b): number => {
      return this.compare(this.orderHelper.getOrderDate(a.order), this.orderHelper.getOrderDate(b.order), ascending);
    });
  }

  sortByCandidateName(reports: RefReport[], ascending: boolean): RefReport[] {
    return reports.sort((a, b): number => {
      return this.compare(a.order.candidateInfo.name.toUpperCase(), b.order.candidateInfo.name.toUpperCase(), ascending);
    });
  }

  sortByCompany(reports: RefReport[], ascending: boolean): RefReport[] {
    return reports.sort((a, b): number => {
      return this.compare(a.order.company.toUpperCase(), b.order.company.toUpperCase(), ascending);
    });
  }

  sortByPosition(reports: RefReport[], ascending: boolean): RefReport[] {
    return reports.sort((a, b): number => {
      return this.compare(a.order.position.toUpperCase(), b.order.position.toUpperCase(), ascending);
    });
  }

  sortByStatus(reports: RefReport[], ascending: boolean): RefReport[] {
    return reports.sort((a, b): number => {
      return this.compare(a.order.state, b.order.state, ascending);
    });
  }

  searchReferenceCheckOrders(reports: RefReport[], search: string): RefReport[] {
    if (!search) {
      return reports;
    }

    let searchResult: RefReport[] = [];
    searchResult = reports.filter(report => report.order.id.toLowerCase().startsWith(search.toLowerCase()));

    if (!searchResult.length) {
      searchResult = reports.filter(report => report.order.position.toLowerCase().includes(search.toLowerCase()));
    }
    if (!searchResult.length) {
      searchResult = reports.filter(report => report.order.candidateInfo.name.toLowerCase().includes(search.toLowerCase()));
    }

    return searchResult;
  }

  private compare(a: any, b: any, ascending: boolean): number {
    if (a === null || a === undefined) { return -1 * (ascending ? -1 : 1); }
    if (b === null || b === undefined) { return 1 * (ascending ? -1 : 1); }
    if (a > b) { return -1 * (ascending ? -1 : 1); }
    if (a < b) { return 1 * (ascending ? -1 : 1); }
    return 0;
  }
}
