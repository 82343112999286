import { DeletionPolicy } from '@enums/deletion-policy.enum';
import { Deserializable } from "./deserializable.model";

export class CustomerPSConfig implements Deserializable {
    checkCitizenship: boolean;
    lawIncluded: boolean;
    omitConsent: boolean;
    followUpGu: boolean;
    flagLimit: number;
    riscOrderer: boolean;
    riscRecipient: boolean;
    spiReporting: string;
    scsQuestionIds: string[];
    deletionPolicy: DeletionPolicy;
    orderInstructions: string[];

    constructor(input?: any) {
        this.checkCitizenship = false;
        this.lawIncluded = false;
        this.omitConsent = false;
        this.followUpGu = false;
        this.flagLimit = 0;
        this.riscOrderer = false;
        this.riscRecipient = false;
        this.spiReporting = null;
        this.scsQuestionIds = [];
        this.deletionPolicy = DeletionPolicy.RETAIN;
        this.orderInstructions = [];

        if (input) { this.deserialize(input); }
    }

    storePsOrders(): boolean {
        return this.deletionPolicy === DeletionPolicy.RETAIN ? true : false;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
