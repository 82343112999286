import { Deserializable } from '@models/deserializable.model';

export class BkgLambdaResponse implements Deserializable {
  public status: string;
  public message: string;
  public result: any[];

  constructor(input?: any) {
    this.status = '';
    this.message = '';
    this.result = [];

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  isSuccessfull(): boolean {
    return this.status === 'OK';
  }
}
