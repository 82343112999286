import { Deserializable } from "./deserializable.model";

export class JobSCDetails implements Deserializable {
  surveyId: string;
  flags: number;
  scheduleApproved: boolean;
  activationTime: string;
  scheduleId: string;
  followUp: boolean;

  constructor(input?: any) {
    this.surveyId = null;
    this.flags = null;
    this.scheduleApproved = false;
    this.activationTime = null;
    this.scheduleId = null;
    this.followUp = false;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

