import { Deserializable } from '@models/deserializable.model';

export class CustomerContact implements Deserializable {
  custNo: string;
  ccName: string;
  ccAdress: string;
  ccPhone: string;
  ccEmail: string;
  fetchStatus: string;

  constructor(input?: any) {
    this.custNo = null;
    this.ccName = null;
    this.ccAdress = null;
    this.ccPhone = null;
    this.ccEmail = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
