import { Deserializable } from '@models/deserializable.model';
import { PType } from '@enums/p-type.enum';
import { PClass } from '@enums/p-class.enum';

export class DataPart implements Deserializable {
  id: string;
  partType: PType;
  partClass: PClass;
  classId: string;
  partDone: boolean;
  partLastCheckin: Date;
  partLastChangedBy: string;
  data: string;
  modified: string;
  fetchStatus: string;

  constructor(input?: any) {
    this.id = null;
    this.partType = null;
    this.partClass = null;
    this.classId = null;
    this.partDone = false;
    this.partLastCheckin = null;
    this.partLastChangedBy = null;
    this.data = null;
    this.modified = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
