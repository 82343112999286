import { Deserializable } from '@models/deserializable.model';

export class RefSettings implements Deserializable {
  id: string;
  classType: string;
  customerId: string;
  alternativePsText: boolean;
  defaultMinReferences: number;
  defaultMaxReferences: number;
  bankIdVerification: boolean;
  noVerification: boolean;
  logoUrl: string;
  urlPolicy: string;
  contactDetails: string;
  contactDetailsDataProtectionOfficer: string;

  constructor(input?: any) {
    this.id = null;
    this.classType = 'com.sri.ref.backend.dto.Settings';
    this.customerId = null;
    this.alternativePsText = false;
    this.defaultMinReferences = 1;
    this.defaultMaxReferences = 5;
    this.bankIdVerification = true;
    this.noVerification = false;
    this.logoUrl = '';
    this.urlPolicy = null;
    this.contactDetails = null;
    this.contactDetailsDataProtectionOfficer = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  validate(): string {
    if (this.defaultMinReferences < 1) { return 'Min antal referenser ogiltigt'; }
    if (this.defaultMaxReferences > 10) { return 'Max antal referenser ogiltigt'; }
    if (this.defaultMinReferences > this.defaultMaxReferences) { return 'Min/max antal referenser ogiltigt'; }
    if (!this.bankIdVerification && !this.noVerification) { return 'Välj minst en verifieringsmetod'; }
    if (this.urlPolicy && !this.urlPolicy.startsWith('http') && !this.urlPolicy.startsWith('https')) { this.urlPolicy = 'https://' + this.urlPolicy; }

    return null;
  }
}
