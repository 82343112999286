import { Deserializable } from '@models/deserializable.model';
import { RefOrder } from './ref-order.model';
import { RefSurvey } from './ref-survey.model';
import { RefReference } from './ref-reference.model';

export class RefReport implements Deserializable {
  order: RefOrder;
  survey: RefSurvey;
  references: any[];

  constructor(input?: any) {
    this.order = null;
    this.survey = null;
    this.references = [];

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.order) {
      this.order = new RefOrder(input.order);
    }
    if (input.survey) {
      this.survey = new RefSurvey(input.survey);
    }
    if (input.references) {
      this.references = input.references.map(reference => new RefReference(reference));
    }
    return this;
  }
}
