import { Deserializable } from '@models/deserializable.model';
import { RefBankIdOperation } from '../enums/ref-bankid-operation.enum';
import { RefBankIdMessage } from '../enums/ref-bankid-message.enum';

export class RefBankID implements Deserializable {
  classType: string;
  personalNumber: string;
  signMessage: string;
  pollID: string;
  operation: RefBankIdOperation;
  message: RefBankIdMessage;
  key: string;

  constructor(input?: any) {
    this.key = null;
    this.personalNumber = null;
    this.signMessage = null;
    // this.classType = 'com.sri.ref.backend.dto.BankID';
    this.pollID = null;
    this.operation = null;
    this.message = null;
  

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  validate(): string {
    if (!this.isSsnValid()) { return 'Personnummer ogiltigt'; }

    return null;
  }

  isSsnValid(): boolean {
    const MIN_YEAR = 1900;
    const MIN_MONTH = 1;
    const MAX_MONTH = 12;
    const MIN_DAY = 1;
    const MAX_DAY = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (!this.personalNumber) { return false; }
    if (this.personalNumber.length !== 13) { return false; }
    if (this.personalNumber.substr(8, 1) !== '-') { return false; }
    if (!Number.isInteger(Number(this.personalNumber.split('-')[0]))) { return false; }
    if (!Number.isInteger(Number(this.personalNumber.split('-')[1]))) { return false; }

    const year = Number(this.personalNumber.substring(0, 4));
    const month = Number(this.personalNumber.substring(4, 6));
    const day = Number(this.personalNumber.substring(6, 8));
    if ((year < MIN_YEAR) || (month < MIN_MONTH) || (month > MAX_MONTH)) { return false; }
    if ((day < MIN_DAY) || (day > MAX_DAY[month - 1])) { return false; }
    if (year > new Date().getFullYear() - 1) { return false; }

    let ssn = this.personalNumber.substring(2);
    ssn = ssn.replace('-', '');
    const multiply = [2, 1, 2, 1, 2, 1, 2, 1, 2, 1];
    let sum = 0;
    for (let i = 0; i < ssn.length; i++) {
      const num = Number(ssn[i]);
      let calc = num * multiply[i];
      if (calc > 9) {
        calc -= 9;
      }
      sum += calc;
    }
    if (sum % 10 !== 0) { return false; }

    return true;
  }
}
