import { Deserializable } from '@models/deserializable.model';
import { Job } from '@models/job.model';

export class Order implements Deserializable {
  id: string;
  ordered: boolean;
  finalized: boolean;
  discount: number;
  type: string;
  name: string;
  customerId: string;
  customerOrderer: string;
  customerRecipients: string[];
  jobs: Job[];
  timestamp: Date;
  progress: number;
  modified: string;
  fetchStatus: string;

  constructor(input?: any) {
    this.id = null;
    this.ordered = null;
    this.finalized = null;
    this.discount = 0;
    this.type = null;
    this.name = null;
    this.customerId = null;
    this.customerOrderer = null;
    this.customerRecipients = [];
    this.jobs = [];
    this.timestamp = null;
    this.progress = 0;
    this.modified = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.jobs) {
      this.jobs = input.jobs.map(job => new Job(job));
    }
    return this;
  }

  countJobs(): number {
    if (!(this.jobs && this.jobs.length)) { return 0; }
    return this.jobs.length;
  }

  isLfd = (): boolean => this.jobs.some(j => j.isLfd());
  isGu = (): boolean => this.jobs.some(j => j.isGU());
  isImported = (): boolean => this.jobs.some(j => j.isImported());
  isSpi = (): boolean => this.jobs.some(j => j.isSPI());
  isSecurityClearance = (): boolean => this.jobs.some(j => j.isServiceSecurityClearance());

  isLfdFollowUp(): boolean {
    return this.jobs.some((job) => job.isServiceLfdFollowup());
  }
}
