import { Component, OnInit, Input } from '@angular/core';
import { User } from '@models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@services/data.service';
import { Job } from '@models/job.model';
import * as PDFJS from 'pdfjs-dist';
import { ReportType } from '@enums/report-type.enum';
PDFJS.GlobalWorkerOptions.workerSrc = 'pdf.worker.js'; // TODO: Can't find worker

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.css']
})
export class ViewPdfComponent implements OnInit {
  @Input() data: any;
  @Input() title = 'sri_doc';
  @Input() downloadable = false;
  @Input() job: Job;
  @Input() reportType: ReportType;
  @Input() useNewMethod;
  loading = false;
  user: User;

  // PDFJS
  pdfDoc = null;
  pageNum = 1;
  pageNumTotal = 1;
  pageRendering: any = false;
  pageNumPending: any = null;
  scale: any = 2;
  canvas: any = null;
  ctx: any = null;

  constructor(
    private modal: NgbActiveModal,
    private dataServ: DataService
  ) { }

  ngOnInit() {
    this.user = this.dataServ.getUser();
    // Setup PDF
    this.canvas = document.getElementById('the-canvas');
    this.ctx = this.canvas.getContext('2d');
    this.start();
  }


  // HANDLE PDF
  start() {

    if (this.useNewMethod) {
      const pdfData = atob(this.data);

      const pdfBytes = new Uint8Array(pdfData.length);
      for (let i = 0; i < pdfData.length; i++) {
        pdfBytes[i] = pdfData.charCodeAt(i);
      }
      this.data = pdfBytes;
    }
    

    PDFJS.getDocument({ data: this.data }).promise.then((doc) => {
      this.pdfDoc = doc;
      this.pageNumTotal = this.pdfDoc.numPages;
      this.renderPage(this.pageNum);
    });
  }

  renderPage(num: any) {
    this.pageRendering = true;
    this.pdfDoc.getPage(num).then((page) => {
      const viewport = page.getViewport({ scale: this.scale });
      this.canvas.height = viewport.height;
      this.canvas.width = viewport.width;

      this.canvas.style.height = (viewport.height / 1.5) + 'px';
      this.canvas.style.width = (viewport.width / 1.5) + 'px';

      const renderContext = {
        canvasContext: this.ctx,
        viewport
      };
      const renderTask = page.render(renderContext);

      renderTask.promise.then(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      });
    });
  }

  next() {
    if (this.pageNum >= this.pdfDoc.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }

  previous() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.queueRenderPage(this.pageNum);
  }

  zoomIn() {
    if (this.scale >= 3) {
      return;
    }
    this.scale += 0.2;
    this.queueRenderPage(this.pageNum);
  }

  zoomOut() {
    if (this.scale <= 0.6) {
      return;
    }
    this.scale -= 0.2;
    this.queueRenderPage(this.pageNum);
  }

  queueRenderPage(num: any) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  clickPdf() {
    this.zoomIn();
  }

  context() {
    this.zoomOut();
    return false;
  }

  onDone() {
    this.modal.close();
  }

  onDownload() {
    const pdf = btoa(this.data);

    const binary = atob(pdf.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, `${this.title}.pdf`);
    } else {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.download = `${this.title}.pdf`;
      document.body.append(link);
      link.click();
    }

    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  isDownloadVisible(): boolean {
    return this.downloadable;
  }

  isDownloadDisabled(): boolean {
    return (this.loading ||
      (this.job.isBK() && !this.user.hasBackgroundCheckDownloadReport()) ||
      (this.job.isLfd() && !this.user.hasLfdDownloadReport())) ||
      this.isGUDownloadDisabled();
  }

  isGUDownloadDisabled(): boolean {
    switch (this.reportType) {
      case ReportType.GU:
        return this.job.isGU() && !this.user.hasSecurityScreeningDownloadReport();
      case ReportType.SPI:
      case ReportType.ATTACHMENT:
        return this.job.isSPI() && !this.user.hasSpiDownloadReport();
      default:
        return false;
    }
  }
}
