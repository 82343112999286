import { Deserializable } from '@models/deserializable.model';

export class VehicleOwnership implements Deserializable {
  vehicle: string;
  regNr: string;
  modelYear: string;
  unpaidVehicleTax: string;
  unpaidRoadTrafficFee: string;
  inTrafic: string;
  drivingBan: string;
  leasing: string;
  comment: string;

  constructor(input?: any) {
    this.vehicle = '';
    this.regNr = '';
    this.modelYear = '';
    this.unpaidVehicleTax = '';
    this.unpaidRoadTrafficFee = '';
    this.inTrafic = '';
    this.drivingBan = '';
    this.leasing = '';
    this.comment = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
