import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@services/data.service';

@Pipe({
  name: 'analystEmail'
})
export class AnalystEmailPipe implements PipeTransform {

  constructor(private data: DataService) { }

  transform(userId: string, shorten: boolean): string {
    if (!userId) { return 'Ej tilldelad'; }

    for (const analyst of this.data.getAnalysts()) {
      if (analyst.userId === userId) {
        return shorten ? analyst.getName() : analyst.userEmail;
      }
    }
    return userId.substr(0, 8).toUpperCase();
  }
}
