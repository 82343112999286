import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@services/data.service';

@Pipe({
  name: 'spiLeaderEmail'
})
export class SpiLeaderEmailPipe implements PipeTransform {

  constructor(private data: DataService) { }

  transform(userId: string, shorten: boolean): string {
    if (!userId) { return 'Ej tilldelad'; }
    for (const spiLeader of this.data.getSpiLeaders()) {
      if (spiLeader.userId === userId) {
        return shorten ? spiLeader.getName() : spiLeader.userEmail;
      }
    }
    return userId.substr(0, 8).toUpperCase();
  }
}
