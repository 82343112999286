import { Injectable } from '@angular/core';
import { Pager } from '@models/pager.model';

@Injectable()
export class PagerService {
  PAGE_SIZE = 10;
  PAGES_SHOWN = 9;

  constructor() { }

  getPager(list: any[], page: number, pageSize: number = this.PAGE_SIZE): Pager {
    const pager = new Pager();
    if (!(list && list.length)) { return pager; }

    pager.totalItems = list.length;
    pager.pageSize = pageSize;
    pager.totalPages = Math.ceil(pager.totalItems / pager.pageSize);
    pager.currentPage = Math.min(pager.totalPages, Math.max(page, 1));

    const maxPages = Math.min(pager.totalPages, this.PAGES_SHOWN);
    pager.startPage = this.between(1, pager.currentPage - (Math.ceil((maxPages + 1) * 0.5) - 1), pager.totalPages - (maxPages - 1));
    pager.endPage = this.between(maxPages, pager.currentPage + (Math.floor((maxPages + 1) * 0.5) - 1), pager.totalPages);

    pager.pages = [];
    for (let i = pager.startPage; i <= pager.endPage; i++) {
      pager.pages.push(i);
    }

    pager.startItem = (pager.currentPage - 1) * pager.pageSize;
    pager.endItem = Math.min(pager.startItem + pager.pageSize, pager.totalItems);

    return pager;
  }

  paginateList(pager: Pager, list: any[]): any[] {
    if (!(list && list.length)) { return []; }
    return list.slice(pager.startItem, pager.endItem);
  }

  private between(min: number, value: number, max: number): number {
    return Math.max(Math.min(value, max), min);
  }
}
