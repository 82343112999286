import { Injectable } from '@angular/core';
import { RefSurvey } from '@models/ref-survey.model';

@Injectable()
export class RefSurveyHelperService {

  constructor() { }

  sortByTitle(surveys: RefSurvey[], ascending: boolean): RefSurvey[] {
    return surveys.sort((a, b): number => {
      return this.compare(a.title.toUpperCase(), b.title.toUpperCase(), ascending);
    });
  }

  sortByDescription(surveys: RefSurvey[], ascending: boolean): RefSurvey[] {
    return surveys.sort((a, b): number => {
      return this.compare(a.description.toUpperCase(), b.description.toUpperCase(), ascending);
    });
  }

  sortByQuestionCount(surveys: RefSurvey[], ascending: boolean): RefSurvey[] {
    return surveys.sort((a, b): number => {
      return this.compare(a.getQuestionCount(), b.getQuestionCount(), ascending);
    });
  }

  private compare(a: any, b: any, ascending: boolean): number {
    if (a === null || a === undefined) { return -1 * (ascending ? -1 : 1); }
    if (b === null || b === undefined) { return 1 * (ascending ? -1 : 1); }
    if (a > b) { return -1 * (ascending ? -1 : 1); }
    if (a < b) { return 1 * (ascending ? -1 : 1); }
    return 0;
  }
}
