import { Injectable } from '@angular/core';
import { User } from '@models/user.model';

@Injectable()
export class UserHelperService {
  constructor() { }

  getUserLandingPageRoute(user: User): string {
    if (user.isAdmin()) {
      return 'admin';
    }
    if (user.isCoordinator() || user.isSpiCoordinator()) {
      return 'coordinator';
    }
    if (user.isSpiLeader()) {
      return 'spi';
    }
    if (user.isAnalyst()) {
      return 'analyst';
    }
    if (user.isDataCollector()) {
      return 'collected-data';
    }
    if (user.isCustomerAdmin()) {
      return 'customer-admin';
    }
    if (user.hasServiceBackgroundCheck()) {
      return 'background-check';
    }
    if (user.hasServiceProtectiveSecurity()) {
      return 'sak';
    }
    if (user.hasServiceReferenceCheck()) {
      return 'reference-check';
    }
    if (user.hasLfd()) {
      return 'lfd';
    }

    return 'account';
  }
}
