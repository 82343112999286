import { Deserializable } from "./deserializable.model";

export class JobRiscGrades implements Deserializable {

  overallGrade: string;
  lawGrade: string;
  economyGrade: string;
  companyGrade: string;
  internetGrade: string;

  constructor(input?: any) {
    this.overallGrade = null;
    this.lawGrade = null;
    this.economyGrade = null;
    this.companyGrade = null;
    this.internetGrade = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
