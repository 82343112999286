import { AbstractControl } from '@angular/forms';

export function PhoneValidator(control: AbstractControl) {
  const phoneRegex = new RegExp("^\\+[1-9]{1}[0-9]{5,14}$");

  if (!phoneRegex.test(control.value)) {
    return { validPhone: true }
  }

  return null;
}
