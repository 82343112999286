import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';

import { Base64Service } from '@services/base64.service';
import { CustomerHelperService } from '@services/customer-helper.service';
import { CustomerPermissionHelperService } from '@services/customer-permission-helper.service';
import { HelperService } from '@services/helper.service';
import { HttpBackgroundCheckService } from '@services/http-background-check.service';
import { HttpReferenceCheckService } from '@services/http-reference-check.service';
import { JobHelperService } from '@services/job-helper.service';
import { OrderHelperService } from '@services/order-helper.service';
import { PagerService } from '@services/pager.service';
import { UserPermissionHelperService } from '@services/user-permission-helper.service';
import { PopupService } from '@services/popup.service';
import { RefOrderHelperService } from '@services/ref-order-helper.service';
import { RefReportHelperService } from '@services/ref-report-helper.service';
import { RefSurveyHelperService } from '@services/ref-survey-helper.service';
import { UserHelperService } from '@services/user-helper.service';
import { AccountComponent } from './components/account/account.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './http-interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { PersonHelperService } from '@services/person-helper.service';

@NgModule({
  declarations: [
    NotFoundComponent,
    AccountComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Title,
    AuthGuard,
    AuthService,
    DataService,
    HttpBackgroundCheckService,
    HttpReferenceCheckService,
    CustomerHelperService,
    UserPermissionHelperService,
    CustomerPermissionHelperService,
    OrderHelperService,
    JobHelperService,
    RefReportHelperService,
    RefOrderHelperService,
    RefSurveyHelperService,
    PopupService,
    PagerService,
    Base64Service,
    HelperService,
    UserHelperService,
    PersonHelperService
  ],
  exports: [
    NotFoundComponent
  ]
})
export class CoreModule { }
