import { Deserializable } from "./deserializable.model";

export class Schedule implements Deserializable {
  id: string;
  date: string;

  constructor(input?: any) {
    this.id = null;
    this.date = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}