import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Job } from '@models/job.model';
import { Attachment } from '@models/attachment.model';
import { HttpBackgroundCheckService } from '@services/http-background-check.service';

@Component({
  selector: 'app-job-attachment',
  templateUrl: './job-attachment.component.html',
  styleUrls: ['./job-attachment.component.css']
})
export class JobAttachmentComponent {
  @Input() job: Job;
  @Input() locked: boolean;
  @Output() saveOrder = new EventEmitter();
  edit = false;
  loading = false;
  error: string;
  attachments: Attachment[] = [];
  maxAttachments = 3;

  constructor(
    private http: HttpBackgroundCheckService
  ) { }

  onEdit() {
    this.edit = !this.edit;

    if (this.edit && this.job.id == null) {
      this.saveOrder.emit();
    }

    if (this.edit) {
      this.getAttachmentList();
    }
  }

  onFileInputChange(event) {
    this.error = null;

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const attachment = new Attachment();
        attachment.jobId = this.job.id;
        attachment.filename = file.name;
        attachment.file = reader.result.toString().split(',')[1];
        this.saveAttachment(attachment);
      };
      reader.onerror = error => {
        this.error = 'Fel vid läsning av fil';
      };
    }
  }

  onAttachmentDelete(filename: string) {
    this.loading = true;
    this.error = null;

    const attachment = new Attachment();
    attachment.jobId = this.job.id;
    attachment.filename = filename;

    this.http.postAttachmentDelete(attachment)
      .subscribe(response => {
        this.loading = false;
        this.attachmentListRemoveEntry(attachment.filename);
      },
      error => {
        this.error = error.message;
        this.loading = false;
      });
  }

  saveAttachment(attachment: Attachment) {
    if (!this.isValidFileType(attachment.filename)) {
      this.error = 'Fil måste vara pdf, png eller jpg/jpeg';
      return;
    }

    this.loading = true;
    this.error = null;

    this.http.postAttachment(attachment)
      .subscribe(response => {
        this.loading = false;
        this.attachmentListAddEntry(response);
      },
      error => {
        this.error = error.message;
        this.loading = false;
      });
  }

  getAttachmentList() {
    const attachment = new Attachment();
    attachment.jobId = this.job.id;

    this.loading = true;
    this.error = null;
    this.http.postAttachmentList(attachment)
      .subscribe(response => {
        this.loading = false;
        this.attachments = response;
      },
      error => {
        this.error = error.message;
        this.loading = false;
      });
  }

  attachmentListAddEntry(attachment: Attachment) {
    const matchingFilenames = this.attachments.filter(att => att.filename === attachment.filename);
    if (matchingFilenames.length === 0) {
      this.attachments.push(attachment);
    }
  }

  attachmentListRemoveEntry(filename: string) {
    for (let i = 0; i < this.attachments.length; i++) {
      if (this.attachments[i].filename === filename) {
        this.attachments.splice(i, 1);
        break;
      }
    }
  }

  showAttachments(): boolean {
    return this.attachments !== null && this.attachments.length > 0;
  }

  isMaxAttachments(): boolean {
    return this.attachments.length >= this.maxAttachments;
  }

  isValidFileType(filename: string): boolean {
    filename = filename.toLowerCase();
    if (filename.endsWith('.pdf')) { return true; }
    if (filename.endsWith('.docx')) { return true; }
    if (filename.endsWith('.png')) { return true; }
    if (filename.endsWith('.jpg')) { return true; }
    if (filename.endsWith('.jpeg')) { return true; }
    return false;
  }
}
