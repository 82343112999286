import { Deserializable } from './deserializable.model';
import { CsntState } from '../enums/csnt-state.enum';
import { CsntType } from '../enums/csnt-type.enum';

export class Consent implements Deserializable {
  createTime: string;
  time: string;
  approved: boolean;
  url: string;
  state: CsntState;
  consentType: CsntType;
  consentKey: string;
  pollGuid: string;
  modified: string;
  fetchStatus: string;

  constructor(input?: any) {
    this.createTime = null;
    this.time = null;
    this.approved = null;
    this.url = null;
    this.state = null;
    this.consentType = null;
    this.consentKey = null;
    this.pollGuid = null;
    this.modified = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  isStatePendingAnswer(): boolean { return this.state === CsntState.CsntPendingAnswer; }
  isStateAccepted(): boolean { return this.state === CsntState.CsntAccepted; }
  isStateDeclined(): boolean { return this.state === CsntState.CsntDeclined; }
  isStateTimedOut(): boolean { return this.state === CsntState.CsntTimedOut; }

  isTypeJobStart(): boolean { return this.consentType === CsntType.JobStart; }
  isTypeReportApproval(): boolean { return this.consentType === CsntType.ReportApproval; }
  isTypeWithdrawnConsent(): boolean { return this.consentType === CsntType.WithdrawnConsent; }
}
