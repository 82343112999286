import { Component, OnInit, Input } from '@angular/core';
import { JobState } from '@enums/job-state.enum';

@Component({
  selector: 'app-bkg-job-status',
  templateUrl: './bkg-job-status.component.html',
  styleUrls: ['./bkg-job-status.component.css']
})
export class BkgJobStatusComponent implements OnInit {
  @Input() state: JobState;
  @Input() schedule?: string;

  constructor() { }

  ngOnInit() { }

  isNull(): boolean { return !this.state; }
  isCreated(): boolean { return this.state === JobState.CREATED && !this.schedule; }
  isPendingConsent(): boolean { return this.state === JobState.PENDING_CONSENT && !this.schedule; }
  isDeclined(): boolean { return this.state === JobState.DECLINED && !this.schedule; }
  isAnalysing(): boolean { return this.state === JobState.ANALYSING && !this.schedule; }
  isInterviewing(): boolean { return this.state === JobState.INTERVIEWING && !this.schedule; }
  isPendingApproval(): boolean { return this.state === JobState.PENDING_APPROVAL && !this.schedule; }
  isApproved(): boolean { return this.state === JobState.APPROVED && !this.schedule; }
  isArchived(): boolean { return this.state === JobState.ARCHIVED && !this.schedule; }
  isTimedOut(): boolean { return this.state === JobState.TIMED_OUT && !this.schedule; }
  isSurveying(): boolean { return this.state === JobState.JOB_SURVEYING && !this.schedule; }
  isScheduled(): boolean { return this.state === JobState.SCHEDULED && !this.schedule; }
}
