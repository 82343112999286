import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  preserveWhitespaces: true
})
export class PopupComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() text: string;
  @Input() timer: number;
  @Input() textOnly: boolean;

  code: string;

  CODE_MAX_LENGTH = 6;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.setTimer();
  }

  onDone() {
    this.activeModal.close(true);
  }

  onExit() {
    this.activeModal.dismiss(true);
  }

  onApprove() {
    this.activeModal.close(true);
  }

  onConfirmCode() {
    this.activeModal.close(this.code);
  }

  onCancel() {
    this.activeModal.close(false);
  }

  setTimer() {
    if (this.timer) {
      setTimeout(() => this.onDone(), this.timer);
    }
  }

  isConfirmCodeDisabled(): boolean {
    return !this.code;
  }

  isTypeSuccess(): boolean {
    return this.type === 'success';
  }

  isTypeConfirm(): boolean {
    return this.type === 'confirm';
  }

  isTypeConfirmCheckbox(): boolean {
    return this.type === 'confirmCb';
  }

  isTypeSchedule(): boolean {
    return this.type === 'schedule';
  }

  isTypeDanger(): boolean {
    return this.type === 'danger';
  }

  isTypeCode(): boolean {
    return this.type === 'code';
  }

  isTypePassword(): boolean {
    return this.type === 'password';
  }

  isTypePolicy(): boolean {
    return this.type === 'policy';
  }

  isTypeApproveDeny(): boolean {
    return this.type === 'approve-deny';
  }

  isTextOnly(): boolean {
    return this.textOnly;
  }
}
