import { Deserializable } from '@models/deserializable.model';
import { JobState } from '@enums/job-state.enum';
import { ServiceType } from '@enums/service-type.enum';
import { Gender } from '@enums/gender.enum';
import { DataType } from '@enums/data-type.enum';
import { Assignment } from '@models/assignment.model';
import { JobStateLog } from '@models/job-state-log.model';
import { Consent } from '@models/consent.model';
import { CustomerScreeningQuestion } from '@models/customer-screening-question.model';
import { CollectedDataStatus } from '@enums/collected-data-status.enum';
import { JobLFDDetails } from './job-lfd-details.model';
import { JobSpiDetails } from './job-spi-details.model';
import { JobSCDetails } from './job-sc-details.model';
import { JobRiscGrades } from './job-riscgrades.model';
import { JobSchedule } from './job-schedule.model';
import { CustomerScreeningCheckpoint } from './customer-screening-checkpoint.model';
import { ScreeningCheckpoint } from '@enums/screening-checkpoint.enum';

export class Job implements Deserializable {
  id: string;
  orderId: string;
  baseInfoId: string;
  customerId: string;
  invoiceCompany: string;
  orderInstruction: string;
  staffId: string;
  assignments: Assignment[];
  jobStateLog: JobStateLog[];
  consents: Consent[];
  progress: number;
  finalized: boolean; // SHOULD BE REMOVED
  currState: JobState;
  prevState: JobState;
  createTime: string;
  extendedGDPR: boolean;
  costCenter: string;
  webhookURL: string;
  service: ServiceType;
  position: string;
  description: string;
  gender: Gender;
  ageGroup: string;
  screeningQuestions: CustomerScreeningQuestion[];
  screeningCheckpoints: CustomerScreeningCheckpoint[];
  verifyFormerEmployer: boolean;
  verifyEducation: boolean;
  collectedDataStatus: CollectedDataStatus;
  lfdDetails: JobLFDDetails;
  scDetails: JobSCDetails;
  schedules: JobSchedule[];
  coordinatorNotes: string;

  type: DataType;
  orgNo: string;
  orgName: string;
  SSN: string;
  name: string;
  email: string;
  phone: string;

  modified: string;
  fetchStatus: string;
  storageGuid: string;
  storagePath: string;
  cryptoKey: string;
  baseInquiry: boolean;
  spiDetails: JobSpiDetails;
  performReferenceCheck: boolean;
  spiDate: string;
  delivered: boolean;
  flag: boolean;
  riscGrades: JobRiscGrades;

  constructor(input?: any) {
    this.id = null;
    this.orderId = null;
    this.baseInfoId = null;
    this.customerId = null;
    this.invoiceCompany = null;
    this.orderInstruction = null;
    this.staffId = null;
    this.assignments = null;
    this.jobStateLog = null;
    this.consents = null;
    this.progress = 0;
    this.finalized = false;
    this.currState = null;
    this.prevState = null;
    this.createTime = null;
    this.extendedGDPR = false;
    this.service = null;
    this.position = null;
    this.description = null;
    this.gender = null;
    this.ageGroup = null;
    this.screeningQuestions = [];
    this.screeningCheckpoints = [];
    this.verifyFormerEmployer = null;
    this.verifyEducation = null;
    this.collectedDataStatus = null;
    this.lfdDetails = new JobLFDDetails();
    this.scDetails = new JobSCDetails();
    this.schedules = null;
    this.type = null;
    this.orgNo = null;
    this.orgName = null;
    this.SSN = null;
    this.name = null;
    this.email = null;
    this.phone = null;
    this.modified = null;
    this.fetchStatus = null;
    this.storageGuid = null;
    this.storagePath = null;
    this.cryptoKey = null;
    this.costCenter = null;
    this.webhookURL = null;
    this.delivered = null;
    this.baseInquiry = false;
    this.spiDetails = null;
    this.performReferenceCheck = null;
    this.spiDate = null;
    this.flag = false;
    this.riscGrades = new JobRiscGrades();

    this.coordinatorNotes = null;

    if (input) { this.deserialize(input); }
  }

  static withId(id: string): Job {
    const job = new Job();
    job.id = id;

    return job;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.assignments) {
      this.assignments = input.assignments.map(assignment => new Assignment(assignment));
    }
    if (input.jobStateLog) {
      this.jobStateLog = input.jobStateLog.map(jobStateLog => new JobStateLog(jobStateLog));
    }
    if (input.consents) {
      this.consents = input.consents.map(consent => new Consent(consent));
    }
    if (input.lfdDetails) {
      this.lfdDetails = new JobLFDDetails(input.lfdDetails);
    }
    if (input.spiDetails) {
      this.spiDetails = new JobSpiDetails(input.spiDetails);
    }
    if (input.scDetails) {
      this.scDetails = new JobSCDetails(input.scDetails);
    }
    if (input.schedules) {
      this.schedules = input.schedules.map(schedules => new JobSchedule(schedules));
    }
    if (input.riscGrades) {
      this.riscGrades = new JobRiscGrades(input.riscGrades);
    }
    if (input.screeningQuestions) {
      this.screeningQuestions = input.screeningQuestions.map(screeningQuestion => new CustomerScreeningQuestion(screeningQuestion));
    }
    if (input.screeningCheckpoints) {
      this.screeningCheckpoints = input.screeningCheckpoints.map(screeningCheckpoint => new CustomerScreeningCheckpoint(screeningCheckpoint));
    }
    return this;
  }

  isStateCreated(): boolean { return this.currState === JobState.CREATED; }
  isStatePendingConsent(): boolean { return this.currState === JobState.PENDING_CONSENT; }
  isStateDeclined(): boolean { return this.currState === JobState.DECLINED; }
  isStateInterviewing(): boolean { return this.currState === JobState.INTERVIEWING; }
  isStateAnalysing(): boolean { return this.currState === JobState.ANALYSING; }
  isStatePendingApproval(): boolean { return this.currState === JobState.PENDING_APPROVAL; }
  isStateApproved(): boolean { return this.currState === JobState.APPROVED; }
  isStateArchived(): boolean { return this.currState === JobState.ARCHIVED; }
  isStateTimedOut(): boolean { return this.currState === JobState.TIMED_OUT; }
  isStateSurveying(): boolean { return this.currState === JobState.JOB_SURVEYING; }
  isStateScheduled(): boolean { return this.currState === JobState.SCHEDULED }

  isTypeBlank(): boolean { return this.type === DataType.BLANK; }
  isTypeOrganization(): boolean { return this.type === DataType.ORGANIZATION; }
  isTypeIndividual(): boolean { return this.type === DataType.INDIVIDUAL; }
  isTypeExecutive(): boolean { return this.type === DataType.EXECUTIVE; }

  isServiceBlank(): boolean { return this.service === ServiceType.BLANK; }
  isServiceCoworker(): boolean { return this.service === ServiceType.COWORKER; }
  isServiceSpecialist(): boolean { return this.service === ServiceType.SPECIALIST; }
  isServiceExecutive(): boolean { return this.service === ServiceType.EXECUTIVE; }
  isServiceCompany(): boolean { return this.service === ServiceType.COMPANY; }
  isServiceBoardmember(): boolean { return this.service === ServiceType.BOARDMEMBER; }
  isServiceScreening(): boolean { return this.service === ServiceType.SCREENING; }
  isServicePrescreen(): boolean { return this.service === ServiceType.PRESCREEN; }
  isServiceLfdFollowup(): boolean { return this.service === ServiceType.LFD_FOLLOWUP; }
  isServiceSecurityScreeningBasic(): boolean { return this.service === ServiceType.SECURITY_SCREENING_BASIC; }
  isServiceSecurityScreening(): boolean { return this.service === ServiceType.SECURITY_SCREENING; }
  isServiceSecurityClearance(): boolean { return this.service === ServiceType.SECURITY_CLEARANCE; }
  isServiceSecurityScreeningImport(): boolean { return this.service === ServiceType.SECURITY_SCREENING_IMPORT; }

  isLfd(): boolean {
    if (this.lfdDetails === null) {
      return false;
    }
    return this.isServicePrescreen() || this.isServiceLfdFollowup() || this.lfdDetails.followUp;
  }

  isGU(): boolean {
    return this.isServiceSecurityScreeningBasic() || this.isServiceSecurityScreening();
  }

  isPS(): boolean {
    return this.isGU() || this.isServiceSecurityClearance() || this.isImported();
  }

  isImported(): boolean {
    return this.isServiceSecurityScreeningImport();
  }

  isSPI(): boolean {
    return this.isGU() && this.spiDetails !== null;
  }

  isSPIGUOrImported(): boolean {
    return this.spiDetails !== null && this.isGU() || this.isImported();
  }

  isNotSPIBooked(): boolean {
    return this.isSPI() && this.spiDetails.date === null;
  }

  isCustomerSpiDateEmpty(): boolean {
    return this.spiDate === null || this.spiDate.trim() === "";
  }

  isSPIBooked(): boolean {
    return (this.isSPI() && (this.spiDetails.date !== null || !this.isCustomerSpiDateEmpty())); 
  }

  isBK(): boolean {
    return this.isServiceCoworker() || this.isServiceSpecialist() || this.isServiceExecutive() || this.isServiceScreening();
  }

  isOmitConsent(): boolean {
    return !this.consents && this.isStateAnalysing() || !this.consents && this.isStateInterviewing()
      || !this.consents && this.isStateApproved() || !this.consents && this.isStateArchived() ? true : false;
  }

  isInternetEnabled(): boolean {
    if(this.screeningCheckpoints) {
    let internet = this.screeningCheckpoints.find(cust => cust.checkpoint == ScreeningCheckpoint.INTERNET)
    if(internet && internet.checked) {
    return true;
    }}
   return false;
 }
}
