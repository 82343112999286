import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '@services/helper.service';

@Pipe({
  name: 'shortenId'
})
export class ShortenIdPipe implements PipeTransform {
  public transform(id: string) {
    return HelperService.transformID(id);
  }
}
