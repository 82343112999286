import { Injectable } from '@angular/core';
import { Order } from '@models/order.model';
import { JobHelperService } from './job-helper.service';
import { Job } from '@models/job.model';
import { JobState } from '@enums/job-state.enum';
import { JobScheduleType } from '@enums/job-schedule-type.enum';

@Injectable()
export class OrderHelperService {
  constructor(private jobHelper: JobHelperService) { }

  sortByDate(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number => {
      return this.compare(a.jobs[0].createTime, b.jobs[0].createTime, ascending);
    });
  }

  sortByScActivationDate(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number => {
      return this.compare(a.jobs[0].scDetails.activationTime, b.jobs[0].scDetails.activationTime, ascending);
    });
  }

  sortByConfirmation(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].scDetails.scheduleApproved, b.jobs[0].scDetails.scheduleApproved, ascending));
  }

  sortById(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(Number(a.id), Number(b.id), ascending));
  }

  sortByJobId(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(Number(a.jobs[0].id), Number(b.jobs[0].id), ascending));
  }

  sortByJobName(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].name.toUpperCase(), b.jobs[0].name.toUpperCase(), ascending));
  }

  sortByJobPosition(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].position.toUpperCase(), b.jobs[0].position.toUpperCase(), ascending));
  }

  sortByJobService(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].service, b.jobs[0].service, ascending));
  }

  sortByDeadline(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(this.jobHelper.getDeadline(a.jobs[0]), this.jobHelper.getDeadline(b.jobs[0]), ascending));
  }

  sortByJobState(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].currState, b.jobs[0].currState, ascending));
  }

  sortByJobRiscGrade(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].riscGrades.overallGrade, b.jobs[0].riscGrades.overallGrade, ascending));
  }

  sortByJobSpi(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number =>
      this.compare(a.jobs[0].spiDetails, b.jobs[0].spiDetails, ascending));
  }

  searchOrders(orders: Order[], search: string): Order[] {
    if (!search) {
      return orders;
    }

    let searchResult: Order[] = [];
    for (let order of orders) {
      for (let job of order.jobs) {
        if (job.name.toLowerCase().includes(search.toLowerCase())) {
          searchResult.push(order);
        }
        if (job.position.toLowerCase().includes(search.toLowerCase())) {
          searchResult.push(order);
        }
      }
    }

    if (searchResult.length) {
      return searchResult;
    }

    return orders.filter(order => order.id.toLowerCase().startsWith(search.toLowerCase()));
  }

  searchOrdersProtectiveSecurity(orders: Order[], search: string): Order[] {
    if (!search) { return orders; }

    search = search.toLowerCase();

    return orders.filter(order =>
      order.id.toLowerCase().includes(search) ||
      order.jobs[0].id.toLowerCase().includes(search) ||
      order.jobs[0].name.toLowerCase().includes(search) ||
      order.jobs[0].position.toLowerCase().includes(search)
    );
  }

  searchName(orders: Order[], search: string): Order[] {
    if (!search) {
      return orders;
    }

    let searchResult: Order[] = [];
    for (let order of orders) {
      for (let job of order.jobs) {
        if (job.name.toLowerCase().includes(search.toLowerCase())) {
          searchResult.push(order);
        }
      }
    }

    if (searchResult.length) {
      return searchResult;
    }

    return orders.filter(order => order.id.toLowerCase().startsWith(search.toLowerCase()));
  }

  sortByDateDiffrentTypes(orders: Order[], ascending: boolean): Order[] {
    return orders.sort((a, b): number => {
      const dateA = this.getDateForOrder(a);
      const dateB = this.getDateForOrder(b);

      return this.compare(dateA, dateB, ascending);
    });
  }

  getDateForOrder(order: Order): Date | null {
    let date: Date | null = null;

    if (this.getApprovedJobDate(order.jobs[0]) != null) {
      date = new Date(this.getApprovedJobDate(order.jobs[0]));
    } else if (order.jobs[0].scDetails.activationTime != null) {
      date = new Date(order.jobs[0].scDetails.activationTime);
    } else if (order.jobs[0].schedules != null) {
      date = new Date(this.getScheduleActivationTime(order.jobs[0]));
    }

    return date;
  }

  getScheduleActivationTime(job: Job) {
    let latestActivationTime: Date = null;

    for (const scheduleDetails of job.schedules) {
      if (scheduleDetails.scheduleType === JobScheduleType.SCHEDULE_CREATED || scheduleDetails.scheduleType === JobScheduleType.SCHEDULE_FOLLOW_UP) {
        const activationDate = new Date(scheduleDetails.activationTime);

        if ((!latestActivationTime || activationDate > latestActivationTime)) {
          latestActivationTime = activationDate;
        }
      }
    }

    return latestActivationTime;
  }

  getApprovedJobDate(job: Job): Date {
    for (var stateLog of job.jobStateLog) {
      if (stateLog.state === JobState.APPROVED) {
        return new Date(stateLog.time);
      }
    }
  }

  compare(a: any, b: any, ascending: boolean): number {
    const ascendingMod = ascending ? -1 : 1;
    if (a === null || a === undefined) { return -1 * ascendingMod; }
    if (b === null || b === undefined) { return 1 * ascendingMod; }
    if (a > b) { return -1 * ascendingMod; }
    if (a < b) { return 1 * ascendingMod; }
    return 0;
  }
}
