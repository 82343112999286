import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(text: string, limit: number): any {
    if (!text) { return text; }
    if (text.length < limit) { return text; }

    let newLimit = text.substr(0, limit).lastIndexOf(' ');
    if (newLimit < 1) { newLimit = limit; }
    return `${text.substr(0, newLimit)}...`;
  }
}
