import { Injectable } from '@angular/core';
import { Customer } from '@models/customer.model';

@Injectable()
export class CustomerHelperService {

  constructor() { }

  filterSearch(customers: Customer[], search: string): Customer[] {
    if (!search) { return customers; }

    return customers.filter(cust =>
      (cust.id && cust.id.toLowerCase().includes(search.toLowerCase())) ||
      (cust.custName && cust.custName.toLowerCase().includes(search.toLowerCase())) ||
      (cust.custOrgNo && cust.custOrgNo.toLowerCase().includes(search.toLowerCase())) ||
      (cust.custAdress && cust.custAdress.toLowerCase().includes(search.toLowerCase()))
    );
  }
}
