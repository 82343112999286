import { Deserializable } from "./deserializable.model";

export class CustomerRTConfig implements Deserializable {
    alternativePsText: boolean;

    constructor(input?: any) {
        this.alternativePsText = false;

        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}