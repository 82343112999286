import { Deserializable } from "./deserializable.model";

export class CustomerBKConfig implements Deserializable {
  checkCitizenship: boolean;
  flag: boolean;
  riscOrderer: boolean;
  riscRecipient: boolean;
  orderInstructions: string[];

  constructor(input?: any) {
    this.checkCitizenship = false;
    this.flag = false;
    this.riscOrderer = false;
    this.riscRecipient = false;
    this.orderInstructions = [];

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
