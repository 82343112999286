import { Deserializable } from '@models/deserializable.model';
import { PsPersonStatusLog } from './ps-person-status-log.model';
import { PsPersonNotes } from './ps-person-notes.model';

export class PsPerson implements Deserializable {
  status: string;
  statusLog: PsPersonStatusLog[];
  customerId: string;
  email: string;
  id: string;
  name: string;
  personalNumber: string;
  phone: string;
  service: string;
  notes: PsPersonNotes;
  tags: string[];

  constructor(input?: any) {
    this.status = null;
    this.statusLog = [];
    this.customerId = null;
    this.email = null;
    this.id = null;
    this.name = null;
    this.personalNumber = null;
    this.phone = null;
    this.service = null;
    this.notes = new PsPersonNotes();
    this.tags = [];

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

 createAllTagList(): void {
  this.tags = [
    "1023",
    "2047",
    "3059",
    "4086",
    "5094"
  ];
}

}
