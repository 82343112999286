import { Deserializable } from '@models/deserializable.model';
import { PsPersonNotesFlag } from '@enums/psperson-notes-flag.enum';

export class PsPersonNotes implements Deserializable {
    text: string;
    author: string;
    date: string;
    flag: PsPersonNotesFlag;

    constructor(input?: any) {
        this.text = null;
        this.author = null;
        this.date = null;
        this.flag = null;

        if (input) {
            this.deserialize(input);
        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}