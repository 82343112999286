import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private modalConfig: NgbModalConfig) { }

  ngOnInit() {
    this.setupModalGlobalConfig();
    this.logVersion();
  }

  logVersion() {
    console.log('SRI Portal v' + environment.version);
  }

  setupModalGlobalConfig() {
    this.modalConfig.backdrop = 'static';
    this.modalConfig.keyboard = false;
  }
}
