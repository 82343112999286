import { Injectable } from '@angular/core';
import { BackendService } from './backendService.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Document } from '@models/document.model';

export interface CreateDocumentBody {
    refId: string;
    refType: string;
    group: string;
    type: string;
    format: string;
}

export interface DocumentQueryParams {
  refId?: string;
  type?: string;
  group?: string;
}

export interface UpdateDocumentBody {
  format: string;
  type: string;
}

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends BackendService {
  private baseUrl: string;
  private httpNoInterceptor: HttpClient;

  constructor(http: HttpClient, handler: HttpBackend) {
    super(http);
    this.httpNoInterceptor = new HttpClient(handler);
    this.baseUrl = environment.docApiUrl;
  }

  postDocument(body: CreateDocumentBody): Observable<any> {
    const url = `${this.baseUrl}/documents`;
    return this.post<any>(url, body);
  }

  updateDocument(id: string, body: UpdateDocumentBody): Observable<any> {
    const url = `${this.baseUrl}/documents/${id}`;
    return this.patch<any>(url, body);
  }

  getDocuments(params?: DocumentQueryParams): Observable<Document[]> {
    const url = this.constructUrlWithParams(`${this.baseUrl}/documents`, params as Record<string, string>);

    return this.get<Document[]>(url).pipe(
      map(response => response.map(doc => new Document(doc)))
    );
  }

  getDocument(id: string): Observable<any> {
    const url = `${this.baseUrl}/documents/${id}`;
    return this.get<any>(url);
  }

  downloadFileS3(downloadUrl: string): Observable<Blob> {
    return this.httpNoInterceptor.get(downloadUrl, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf'
      })
    });
  }

  uploadFileS3(uploadUrl: string, file: File): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('PUT', uploadUrl, file, {
      headers: new HttpHeaders({
        'Content-Type': file.type
      }),
    });
    return this.httpNoInterceptor.request(req);
  }
}
