import { Deserializable } from '@models/deserializable.model';
import { RefQuestionType } from '../enums/ref-question-type.enum';

export class RefSurveyAnswer implements Deserializable {
  public freeText: string;
  public optionIndex: number;
  public sliderValue: number;

  constructor(input?: any) {
    this.freeText = '';
    this.optionIndex = -1;
    this.sliderValue = 0;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  validate(type: RefQuestionType): string {
    switch (type) {
      case RefQuestionType.FREETEXT: {
        if (!this.isFreeTextValid()) { return 'Svar saknas'; }
        break;
      }
      case RefQuestionType.OPTIONS: {
        if (!this.isOptionIndexValid()) { return 'Svar saknas'; }
        break;
      }
      case RefQuestionType.SLIDER: {
        if (!this.isSliderValueValid()) { return 'Svar saknas'; }
        break;
      }
    }
    return null;
  }

  isFreeTextValid(): boolean { return !(!this.freeText); } // va
  isOptionIndexValid(): boolean { return this.optionIndex >= 0; }
  isSliderValueValid(): boolean { return this.sliderValue >= 1 && this.sliderValue <= 10; }

  isSliderCommentExpected(): boolean { return this.sliderValue >= 1 }
}
