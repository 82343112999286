import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeSVGModule } from 'ngx-qrcode-svg';

import { BankIdComponent } from './components/bankid/bankid.component';
import { BkgJobStatusComponent } from './components/bkg-job-status/bkg-job-status.component';
import { CollectedDataProgressComponent } from './components/collected-data-progress/collected-data-progress.component';
import { DeadlineComponent } from './components/deadline/deadline.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FlagsComponent } from './components/flags/flags.component';
import { PortalComponent } from './components/header/header.component';
import { JobAttachmentComponent } from './components/job-attachment/job-attachment.component';
import { LinkComponent } from './components/link/link.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { PagerComponent } from './components/pager/pager.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProgressMessageComponent } from './components/progress-message/progress-message.component';
import { RefOrderStatusComponent } from './components/ref-order-status/ref-order-status.component';
import { RefScaleComponent } from './components/ref-scale/ref-scale.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UserPermissionsListComponent } from './components/user-permissions-list/user-permissions-list.component';
import { UserComponent } from './components/user/user.component';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { AnalystEmailPipe } from './pipes/analyst-email.pipe';
import { CustomerNamePipe } from './pipes/customer-name.pipe';
import { ShortenEmailPipe } from './pipes/shorten-email.pipe';
import { ShortenIdPipe } from './pipes/shorten-id.pipe';
import { SpiLeaderEmailPipe } from './pipes/spi-leader-email.pipe';
import { SwedishTranslationCollectedDataPipe } from './pipes/swedish-translation-collected-data.pipe';
import { SwedishTranslationPsPerson } from './pipes/swedish-translation-ps-person.pipe';
import { SwedishTranslationSecurityClass } from './pipes/swedish-translation-security-class.pipe';
import { SwedishTranslationDocument } from './pipes/swedish-translation-document.pipe';
import { SwedishTranslationPipe } from './pipes/swedish-translation.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { UserEmailPipe } from './pipes/user-email.pipe';
import { DocumentComponent } from './components/document/document.component';

@NgModule({
  declarations: [
    PortalComponent,
    ViewPdfComponent,
    LoadingDotsComponent,
    ErrorMessageComponent,
    ProgressMessageComponent,
    SpinnerComponent,
    PagerComponent,
    LinkComponent,
    RefOrderStatusComponent,
    RefScaleComponent,
    UserComponent,
    BkgJobStatusComponent,
    ShortenIdPipe,
    ShortenEmailPipe,
    SwedishTranslationPipe,
    SwedishTranslationCollectedDataPipe,
    SwedishTranslationPsPerson,
    SwedishTranslationSecurityClass,
    SwedishTranslationDocument,
    AnalystEmailPipe,
    SpiLeaderEmailPipe,
    CustomerNamePipe,
    UserEmailPipe,
    TruncateTextPipe,
    PopupComponent,
    JobAttachmentComponent,
    DeadlineComponent,
    CollectedDataProgressComponent,
    UserPermissionsListComponent,
    FilterDropdownComponent,
    FlagsComponent,
    BankIdComponent,
    DocumentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    QRCodeSVGModule
  ],
  exports: [
    PortalComponent,
    ViewPdfComponent,
    LoadingDotsComponent,
    ErrorMessageComponent,
    ProgressMessageComponent,
    SpinnerComponent,
    PagerComponent,
    LinkComponent,
    RefOrderStatusComponent,
    RefScaleComponent,
    UserComponent,
    BkgJobStatusComponent,
    ShortenIdPipe,
    ShortenEmailPipe,
    SwedishTranslationPipe,
    SwedishTranslationCollectedDataPipe,
    SwedishTranslationPsPerson,
    SwedishTranslationSecurityClass,
    SwedishTranslationDocument,
    AnalystEmailPipe,
    SpiLeaderEmailPipe,
    CustomerNamePipe,
    UserEmailPipe,
    TruncateTextPipe,
    JobAttachmentComponent,
    DeadlineComponent,
    CollectedDataProgressComponent,
    UserPermissionsListComponent,
    FilterDropdownComponent,
    FlagsComponent,
    BankIdComponent,
    DocumentComponent
  ],
  entryComponents: [
    PopupComponent,
    UserComponent,
    ViewPdfComponent,
    JobAttachmentComponent
  ]
})
export class SharedModule { }
