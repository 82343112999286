export enum DocumentType {
    PS_SPI = 'PS_SPI',
    PS_SECURITY_INTERVIEW = 'PS_SECURITY_INTERVIEW',
    PS_SECURITY_CALL_NON_PLACED = 'PS_SECURITY_CALL_NON_PLACED',
    PS_SECURITY_CALL_EVENT_TRIGGERED = 'PS_SECURITY_CALL_EVENT_TRIGGERED',
    PS_SECURITY_CALL_EXIT = 'PS_SECURITY_CALL_EXIT',
    PS_CERTIFICATE = 'PS_CERTIFICATE',
    PS_CV = 'PS_CV',
    PS_REFERENCE_CHECK = 'PS_REFERENCE_CHECK',
    PS_OTHER = 'PS_OTHER'
}