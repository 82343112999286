import { Deserializable } from '@models/deserializable.model';
import { RefOrderState } from '../enums/ref-order-state.enum';

export class RefOrderStateLog implements Deserializable {
  time: Date;
  state: RefOrderState;

  constructor(input?: any) {
    this.time = null;
    this.state = null;

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
