import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface GenericResponse { message: string }

@Injectable() export class BackendService {
    protected headers = new HttpHeaders({
    });

    constructor(public http: HttpClient) { }

    protected get<T>(url: string): Observable<T> {
        return this.http.get<T>(url, { headers: this.headers })
            .pipe(
                catchError(e => this.handleError(e))
            );
    }

    protected post<T>(url: string, body: object): Observable<T> {
        return this.http.post<T>(url, body, { headers: this.headers })
            .pipe(
                catchError(e => this.handleError(e))
            );
    }

    protected put<T>(url: string, body: object): Observable<T> {
        return this.http.put<T>(url, body, { headers: this.headers })
            .pipe(
                catchError(e => this.handleError(e))
            );
    }

    protected patch<T>(url: string, body: object): Observable<T> {
        return this.http.patch<T>(url, body, { headers: this.headers })
            .pipe(
                catchError(e => this.handleError(e))
            );
    }

    protected delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(url, { headers: this.headers })
            .pipe(
                catchError(e => this.handleError(e))
            );
    }

    protected handleError(error: HttpErrorResponse) {
        if (!environment.production && environment.debug) {
            console.error(error);
        }

        return throwError(() => new Error(error.error.message));
    }

    protected constructUrlWithParams(url: string, params?: Record<string, string>): string {
        if (!params) {
            return url;
        }

        let httpParams = new HttpParams();
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                httpParams = httpParams.append(key, value);
            }
        }

        const queryString = httpParams.toString();
        return queryString ? `${url}?${queryString}` : url;
    }
}