import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenEmail'
})
export class ShortenEmailPipe implements PipeTransform {

  transform(email: string): any {
    if (!email) { return email; }
    return email.split('@')[0];
  }
}
