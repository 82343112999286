import { Injectable } from '@angular/core';
import { RefOrder } from '@models/ref-order.model';
import { RefOrderState } from '@enums/ref-order-state.enum';

@Injectable()
export class RefOrderHelperService {

  constructor() { }

  getOrderDate(order: RefOrder): Date {
    if (order.isCreated()) { return null; }
    if (!(order.stateLog && order.stateLog.length)) { return null; }

    const stateLog = order.stateLog.find(log => log.state === RefOrderState.ORDERED);
    if (!stateLog) { return null; }

    return stateLog.time;
  }

  filterBySaved(orders: RefOrder[]): RefOrder[] {
    return orders.filter(order => order.isCreated());
  }

  filterByPending(orders: RefOrder[]): RefOrder[] {
    return orders.filter(order => order.isOrdered() || order.isSurveying());
  }

  filterByDone(orders: RefOrder[]): RefOrder[] {
    return orders.filter(order => order.isDone() || order.isTimedout());
  }

  sortById(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(a.id.toUpperCase(), b.id.toUpperCase(), ascending);
    });
  }

  sortByOrderDate(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(this.getOrderDate(a), this.getOrderDate(b), ascending);
    });
  }

  sortByCandidateName(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(a.candidateInfo.name.toUpperCase(), b.candidateInfo.name.toUpperCase(), ascending);
    });
  }

  sortByCompany(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(a.company.toUpperCase(), b.company.toUpperCase(), ascending);
    });
  }

  sortByPosition(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(a.position.toUpperCase(), b.position.toUpperCase(), ascending);
    });
  }

  sortByStatus(orders: RefOrder[], ascending: boolean): RefOrder[] {
    return orders.sort((a, b): number => {
      return this.compare(a.state, b.state, ascending);
    });
  }

  searchOrders(orders: RefOrder[], search: string): RefOrder[] {
    if (!search) {
      return orders;
    }

    let searchResult: RefOrder[] = [];

    for (var order of orders) {
      
      if (search.length > 2 && order.id.toLowerCase().includes(search.toLowerCase())) {
          searchResult.push(order);
        }
        
      if (order.company.toLowerCase().includes(search.toLowerCase())) {
        searchResult.push(order);
      }
      
    }

    if (searchResult.length) {
      return searchResult;
   }

     return orders.filter(order => order.id.toLowerCase().includes(search.toLowerCase()));
  }

  private compare(a: any, b: any, ascending: boolean): number {
    if (a === null || a === undefined) { return -1 * (ascending ? -1 : 1); }
    if (b === null || b === undefined) { return 1 * (ascending ? -1 : 1); }
    if (a > b) { return -1 * (ascending ? -1 : 1); }
    if (a < b) { return 1 * (ascending ? -1 : 1); }
    return 0;
  }
}
