import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@models/job.model';

@Component({
  selector: 'app-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.css']
})
export class FlagsComponent {
  @Input() job: Job;
  flags: number;

  constructor(
  ) { }

  ngOnInit() {
    this.flags = this.job.scDetails.flags;
  }

}
