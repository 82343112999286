export enum JobState {
  CREATED = 'CREATED',
  PENDING_CONSENT = 'PENDING_CONSENT',
  DECLINED = 'DECLINED',
  ANALYSING = 'ANALYSING',
  IN_PROGRESS = 'IN_PROGRESS',
  INTERVIEWING = 'INTERVIEWING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  SCHEDULED_ORDER = 'SCHEDULED_ORDER',
  ARCHIVED = 'ARCHIVED',
  TIMED_OUT = 'TIMED_OUT',
  JOB_SURVEYING = 'JOB_SURVEYING',
  SCHEDULED = 'SCHEDULED'
}
