import { Deserializable } from '@models/deserializable.model';

export class CreditSafeRopPublicClaimsData implements Deserializable {
  PNR: string;
  CREATED_DATE: string;
  AMOUNT: string;
  TYPE: string;
  TYPE_TEXT: string;
  TERM: string;
  COMMENTS: string;

  constructor(input?: any) {
    this.PNR = '';
    this.CREATED_DATE = '';
    this.AMOUNT = '';
    this.TYPE = '';
    this.TYPE_TEXT = '';
    this.TERM = '';
    this.COMMENTS = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
