import { Deserializable } from '@models/deserializable.model';
import { CreditSafeCompany } from './creditsafe-company.model';

export class CreditSafeCompanyEngagementsData implements Deserializable {
  creditSafeCompany: CreditSafeCompany;
  ORG_NUMBER: string;
  COMPANY_NAME: string;
  FROM_DATE: string;
  TO_DATE: string;
  FUNCTION: string;

  constructor(input?: any) {
    this.creditSafeCompany = null;
    this.ORG_NUMBER = '';
    this.COMPANY_NAME = '';
    this.FROM_DATE = '';
    this.TO_DATE = '';
    this.FUNCTION = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
