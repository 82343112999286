import { Deserializable } from '@models/deserializable.model';

export class CustomerBillingData implements Deserializable {
  custNo: string;
  billingAdress: string;
  billingVolume: number;
  billingPeriod: Date;
  billingReference: string;
  billingCustAccount: string;
  billingSriAccount: string;
  billingDiscount: number;
  fetchStatus: string;

  constructor(input?: any) {
    this.custNo = null;
    this.billingAdress = null;
    this.billingVolume = null;
    this.billingPeriod = null;
    this.billingReference = null;
    this.billingCustAccount = null;
    this.billingSriAccount = null;
    this.billingDiscount = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
