import { Deserializable } from '@models/deserializable.model';

export class Report implements Deserializable {
  id: string;
  modified: string;
  custNo: string;
  data: string;
  message: string;
  images: string[];
  delivered: boolean;

  constructor(input?: any) {
    this.id = null;
    this.modified = null;
    this.custNo = null;
    this.data = null;
    this.message = null;
    this.images = [];
    this.delivered = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
