import { Deserializable } from '@models/deserializable.model';

export class DriversLicense implements Deserializable {
  type: string;
  issuedDate: string;

  constructor(input?: any) {
    this.type = '';
    this.issuedDate = '';

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
