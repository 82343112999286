import { Deserializable } from '@models/deserializable.model';

export class APIKeyData implements Deserializable {
  public key: string;
  public keyName: string;
  public keyDescription: string;
  public customerId: string;

  constructor(input?: any) {
    this.key = '';
    this.keyName = '';
    this.keyDescription = '';
    this.customerId = '';

    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
