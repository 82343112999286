export const environment = {
  version: '3.37.1',
  production: false,
  envName: 'TEST',
  debug: true,
  userPoolId: 'eu-north-1_peFRM2den',
  appClientId: '5absmil8u849il3o1akgija3bn',
  apiKey: 'J0crWsy75YadAIKDAYQC86awBlL4U0Vz3kSoSZWs',
  apiUrl: 'https://jemfwqfg1g.execute-api.eu-north-1.amazonaws.com/test',
  scsApiUrl: 'https://2wg26njpa3.execute-api.eu-north-1.amazonaws.com/test',
  refApiKey: '2iT0BdCPmj9mjuhPZExi363Y3tH1Pht68Oc3HAwa',
  refApiUrl: 'https://xgeys3v4ef.execute-api.eu-north-1.amazonaws.com/test',
  tagApiUrl: 'https://7yeqinb0id.execute-api.eu-north-1.amazonaws.com/test',
  docApiUrl: 'https://4ll2sqlc7b.execute-api.eu-north-1.amazonaws.com/test',
  testCustomers: ['2212', '2213', '2219', '256013']
};
