import { PType } from "@enums/p-type.enum";
import { Deserializable } from "./deserializable.model";

export class CustomerLFDConfig implements Deserializable {
    followUpLfdInterval: number;
    followUpLfdStandard: boolean;
    followUpLfdCheckPoints: PType[];

    constructor(input?: any) {
        this.followUpLfdInterval = null;
        this.followUpLfdStandard = null;
        this.followUpLfdCheckPoints = null;
    
        if (input) { this.deserialize(input); }
      }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}