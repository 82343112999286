import { Injectable } from '@angular/core';
import { Person } from '@models/person.model';
import { PsPerson } from '../../shared/models/ps-person.model';
import { SecurityClass } from '@models/security-class.model';

interface PsPersonSecurityClass {
  psPerson: PsPerson;
  securityClasses: SecurityClass[];
}

@Injectable()
export class PersonHelperService {

  constructor() {
  }

  searchPsPerson(psPersons: PsPersonSecurityClass[], search: string): PsPersonSecurityClass[] {
    if (!search) { return psPersons; }

    search = search.toLowerCase();

    return psPersons.filter(person =>
      person.psPerson.name.toLowerCase().includes(search) ||
      person.psPerson.personalNumber.toLowerCase().includes(search) ||
      person.psPerson.email.toLowerCase().includes(search)
    );
  }

  sortByName(psPersons: PsPerson[], ascending: boolean): PsPerson[] {
    return psPersons.sort((a, b): number =>
      this.compare(a.name.toLowerCase(), b.name.toLowerCase(), ascending));
  }

  private compare(a: any, b: any, ascending: boolean): number {
    const ascendingMod = ascending ? -1 : 1;
    if (a === null || a === undefined) { return -1 * ascendingMod; }
    if (b === null || b === undefined) { return 1 * ascendingMod; }
    if (a > b) { return -1 * ascendingMod; }
    if (a < b) { return 1 * ascendingMod; }
    return 0;
  }
}
