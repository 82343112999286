import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LambdaResponse } from '@models/lambda-response.model';
import { RefBankID } from '@models/ref-bankid.model';
import { RefFile } from '@models/ref-file.model';
import { RefOrder } from '@models/ref-order.model';
import { RefPollStatus } from '@models/ref-pollstatus.model';
import { RefReference } from '@models/ref-reference.model';
import { RefReport } from '@models/ref-report.model';
import { RefSettings } from '@models/ref-settings.model';
import { RefSurvey } from '@models/ref-survey.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpReferenceCheckService {
  private headers = new HttpHeaders();
  private baseUrl = environment.refApiUrl;

  constructor(
    private http: HttpClient
  ) {
    this.setHeaders();
  }

  private setHeaders() {
    this.headers = this.headers.append('x-api-key', environment.refApiKey);
  }

  
  // SURVEY
  getSurvey(survey: RefSurvey): Observable<RefSurvey> {
    survey.configureAsSurvey();
    if (!survey.id) { return this.createError('Misslyckades att hämta frågebatteri'); }
    return this.handleRequest('/survey/populate', survey, RefSurvey).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta frågebatteri')); })
    );
  }

  getSurveyTemplate(survey: RefSurvey): Observable<RefSurvey> {
    survey.configureAsTemplate();
    if (!survey.id) { return this.createError('Misslyckades att hämta frågebatteri-mall'); }
    return this.handleRequest('/survey/populate', survey, RefSurvey).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta frågebatteri-mall')); })
    );
  }

  getSurveyTemplateList(survey: RefSurvey): Observable<RefSurvey[]> {
    survey.configureAsTemplate();
    return this.handleRequest('/survey/populate', survey, RefSurvey).pipe(
      catchError(() => { throw(new Error('Misslyckades att hämta frågebatteri-mallar')); })
    );
  }

  saveSurveyTemplate(survey: RefSurvey): Observable<RefSurvey> {
    survey.configureAsTemplate();
    return this.handleRequest('/survey', survey, RefSurvey).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att spara frågebatteri-mall')); })
    );
  }

  deleteSurveyTemplate(survey: RefSurvey): Observable<RefSurvey> {
    survey.configureAsTemplate();
    return this.handleRequest('/survey/delete', survey, RefSurvey).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att radera frågebatteri-mall')); })
    );
  }

  copySurveyTemplate(survey: RefSurvey): Observable<RefSurvey> {
    survey.configureAsSurvey();
    return this.handleRequest('/survey/copy', survey, RefSurvey).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att kopiera frågebatteri-mall')); })
    );
  }

  // ORDER
  getOrder(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order/populate', order, RefOrder).pipe(
      map(response => {
        if (!(response && response.length)) { throw(new Error()); }
        return response.shift();
      }),
      catchError(() => { throw(new Error('Misslyckades att hämta beställning')); })
    );
  }

  getOrderDecrypted(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order/decrypt', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att avkryptera beställning')); })
    );
  }

  getOrders(order: RefOrder): Observable<RefOrder[]> {
    return this.handleRequest('/order/populate', order, RefOrder).pipe(
      catchError(() => { throw(new Error('Misslyckades att hämta beställningar')); })
    );
  }

  getOrderList(order: RefOrder): Observable<RefReport[]> {
    return this.handleRequest('/order/list', order, RefReport).pipe(
      catchError(() => { throw(new Error('Misslyckades att hämta beställningar')); })
    );
  }

  decryptOrders(order: RefOrder): Observable<RefOrder[]> {
    return this.handleRequest('/order/decrypt', order, RefOrder).pipe(
      catchError(() => { throw(new Error('Misslyckades att avkryptera beställningar')); })
    );
  }

  saveOrder(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att spara beställning')); })
    );
  }

  deleteOrder(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order/delete', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att radera beställning')); })
    );
  }

  sendOrder(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order/send', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att skicka beställning')); })
    );
  }

  archiveOrder(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/order/archive', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att arkivera beställning')); })
    );
  }

  // REPORT
  getReport(order: RefOrder): Observable<RefReport> {
    return this.handleRequest('/order/report', order, RefReport).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta rapport')); })
    );
  }

  getReportPDF(order: RefOrder): Observable<RefFile> {
    return this.handleRequest('/order/pdf', order, RefFile).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta PDF')); })
    );
  }

  getReportDecryptedPDF(order: RefOrder): Observable<RefFile> {
    return this.handleRequest('/order/pdf/decrypted', order, RefFile).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta PDF')); })
    );
  }

  // POLLING
  getPollStatus(pollStatus: RefPollStatus): Observable<RefPollStatus> {
    return this.handleRequest('/poll', pollStatus, RefPollStatus).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att hämta poll-status')); })
    );
  }

  // REFERENCE
  getReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/populate', reference, RefReference).pipe(
      map(response => {
        if (!(response && response.length)) { throw(new Error()); }
        return response.shift();
      }),
      catchError(() => { throw(new Error('Misslyckades att hämta referensen')); })
    );
  }

  getReferences(reference: RefReference): Observable<RefReference[]> {
    return this.handleRequest('/reference/populate', reference, RefReference).pipe(
      catchError(() => { throw(new Error('Misslyckades att hämta referenser')); })
    );
  }

  decryptReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/decrypt', reference, RefReference).pipe(
      map(response => {
        if (!(response && response.length)) { throw(new Error()); }
        return response.shift();
      }),
      catchError(() => { throw(new Error('Misslyckades att avkryptera referensen')); })
    );
  }

  decryptReferences(reference: RefReference): Observable<RefReference[]> {
    return this.handleRequest('/reference/decrypt', reference, RefReference).pipe(
      catchError(() => { throw(new Error('Misslyckades att avkryptera referenser')); })
    );
  }

  saveReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference', reference, RefReference).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att spara referens')); })
    );
  }

  verifyReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/verify', reference, RefReference).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att spara referens')); })
    );
  }

  deleteReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/delete', reference, RefReference).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att radera referens')); })
    );
  }

  submitReferences(references: RefReference[]): Observable<RefReference[]> {
    return this.handleRequest('/reference/send', references, RefReference).pipe(
      catchError(() => { throw(new Error('Misslyckades att skicka referenser')); })
    );
  }

  submitReferenceSurvey(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/send/surveyanswers', reference, RefReference).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att skicka svar')); })
    );
  }

  signReference(sign: RefBankID): Observable<RefBankID> {
    return this.handleRequest('/reference/sign', sign, RefBankID).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att signera')); })
    );
  }

  declineReference(reference: RefReference): Observable<RefReference> {
    return this.handleRequest('/reference/decline', reference, RefReference).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att avböja')); })
    );
  }

  // CANDIDATE
  saveCandidate(order: RefOrder): Observable<RefOrder> {
    return this.handleRequest('/candidate', order, RefOrder).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att autentisera')); })
    );
  }
  
  authCandidate(bankid: RefBankID): Observable<RefBankID> {
    return this.handleRequest('/candidate/auth', bankid, RefBankID).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att autentisera')); })
    );
  }

  // SETTINGS
  getSettings(settings: RefSettings): Observable<RefSettings> {
    return this.handleRequest('/customer/settings/populate', settings, RefSettings).pipe(
      map(response => {
        if (!(response && response.length)) { throw(new Error()); }
        return response.shift();
      }),
      catchError(() => { throw(new Error('Misslyckades att hämta inställningar')); })
    );
  }

  saveSettings(settings: RefSettings): Observable<RefSettings> {
    return this.handleRequest('/customer/settings', settings, RefSettings).pipe(
      map(response => response.shift()),
      catchError(() => { throw(new Error('Misslyckades att spara inställningar')); })
    );
  }

  // BILLING
  getArchivedOrders(date1: string, date2: string, customerId: string): Observable<RefOrder[]> {
    const body = {
      startDate: date1,
      stopDate: date2,
      customerID: customerId
    };
    return this.handleRequest('/customer/billing', body, RefOrder).pipe(
      catchError(() => { throw(new Error('Misslyckades att hämta arkiverade beställningar')); })
    );
  }

  // HANDLE REQUEST
  private handleRequest(endpoint: string, body: any, model: any): Observable<any[]> {
    return this.http.post<LambdaResponse>(this.baseUrl + endpoint, body, { headers: this.headers }).pipe(
      map(response => {
        const lambdaResponse = new LambdaResponse(response);
        if (!lambdaResponse || !lambdaResponse.isSuccessfull()) {
          throw(new Error(response.message));
        }
        return lambdaResponse.result.map(data => new model(data));
      }),
      catchError(error => {
        if (!environment.production && environment.debug) { console.error(error); }
        throw(new Error(error.message));
      })
    );
  }

  private createError(error?: string): Observable<any> {
    return new Observable((obs) => {
      obs.error(error);
      obs.complete();
    });
  }
}
