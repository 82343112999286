import { Deserializable } from '@models/deserializable.model';
import { UserGroupId } from 'src/app/shared/enums/user-group-id.enum';

export class UserGroup implements Deserializable {
  public userGroupId: UserGroupId;
  public description: string;
  public updated: Date;
  public created: Date;
  public fetchStatus: string;

  constructor(input?: any) {
    this.userGroupId = null;
    this.description = null;
    this.updated = null;
    this.created = null;
    this.fetchStatus = null;

    if (input) { this.deserialize(input); }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
